import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, getDemoSelected } from '../../../Utils';
import {
  trackAnalysisFinish,
  trackClickTakeMeBack,
  trackGenerationStartSuccess,
  trackGenerationStartError,
} from '../../../analytics';

import { Button, Alert, ProgressBar } from '../../../components';
import BackBtn from '../../../components/backBtn';
import FancyBtn from '../../../components/fancyBtn';
import ErrorModal from '../../../components/modals/errorModal';
import PageHeader from '../../../components/pageHeader';
import PageSpinner from '../../../components/pageSpinner';
import SellingPoint from '../../../components/sellingPoint';

import { URL, DEMO_ID } from '../../../constants';

import {
  getCreationResults,
  generateCreations,
} from '../../../services/api/creations';
import { getTemplateData } from '../../../services/api/template';
import { getClipGroups } from '../../../services/selectGroupsService';

import { useEventStore } from '../../../stores/event';

import DoneBtn from '../components/doneBtn';
import { getAnalysisPageText } from '../components/getText';
import ClipsBlocks from './components/clipsBlocks';
import HlReelPreviewContainer from './components/hlReelPreviewContainer';
import PeopleBlocks from './components/peopleBlocks';
import PersonClipsModal from './components/personClipsModal';

const Analysis = () => {
  const eventId = useParams()?.eventId;

  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const selectionsLocal = useEventStore((state) => state.clipSelections);
  const storeClipSelections = useEventStore((state) => state.setClipSelections);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  // people
  const [clipGroups, setClipGroups] = useState([]);
  const [generatedPeopleIds, setGeneratedPeopleIds] = useState([]);
  const [openedPersonId, setOpenedPersonId] = useState(null);

  // highlight reel
  const [isHlReelProcessing, setIsHlReelProcessing] = useState(false);
  const [hlReel, setHlReel] = useState(null);
  const [requiredShots, setRequiredShots] = useState([]);

  // clips
  const [isClipsProcessing, setIsClipsProcessing] = useState(false);
  const [clips, setClips] = useState(null);
  const [deletingClips, setDeletingClips] = useState([]);
  const [clipSelections, setClipSelections] = useState([]);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const navigate = useNavigate();

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) {
        setPageError(currentEvent.error);
        setIsLoading(false);
        return;
      }

      await fetchPeopleData();
      await fetchClipsData(true);
      await fetchHlReelData(true);

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  // if highlight reel is processing – re-fetch every 10 seconds
  useEffect(() => {
    if (isHlReelProcessing) {
      const hlReelInterval = setInterval(async () => {
        await fetchHlReelData();
      }, 10000);

      return () => clearInterval(hlReelInterval);
    }
  }, [eventId, isHlReelProcessing]);

  // if clips are processing – re-fetch every 10 seconds
  useEffect(() => {
    if (isClipsProcessing) {
      const clipsInterval = setInterval(async () => {
        await fetchClipsData();
      }, 10000);

      return () => clearInterval(clipsInterval);
    }
  }, [eventId, isClipsProcessing]);

  const isAnalysing = useMemo(() => {
    return isClipsProcessing || isHlReelProcessing;
  }, [isClipsProcessing, isHlReelProcessing]);

  // when analysis finish – add GA event
  useEffect(() => {
    if (!isAnalysing) {
      const projectsUpd =
        JSON.parse(localStorage.getItem('analysedProjects')) ?? [];

      // check if eventId is not in localStorage analysedProjects – to avoid duplicate events
      if (!projectsUpd.includes(eventId)) {
        trackAnalysisFinish(currentEvent);

        projectsUpd.push(eventId);
        localStorage.setItem('analysedProjects', JSON.stringify(projectsUpd));
      }
    }
  }, [currentEvent, eventId, isAnalysing]);

  // clip selections – find the matching clips for each person's wildcard spot
  useEffect(() => {
    if (isEmpty(requiredShots) || isEmpty(clipGroups)) return;

    const clipSelectionsTemp = [];

    // for each person group – find the selected clips
    clipGroups.forEach((group) => {
      const groupId = group.face.elementId || null;

      const selectionsObj = {
        faceId: groupId,
        wildcardsFiller: requiredShots.map((shot) => ({
          wildCardShotIndex: shot.shotIndex,
          clipId: null,
        })),
      };

      // find if the person already has selections data or set new object
      const foundSelections =
        selectionsLocal.find((s) => s.faceId === groupId) ?? selectionsObj;

      const checkedClips = [];

      // find matching clips
      foundSelections?.wildcardsFiller.forEach((w) => {
        // if wildcard has NO selected clip –  – find if there's matching one
        if (!w.clipId) {
          const duration = requiredShots.find(
            (shot) => shot.shotIndex === w.wildCardShotIndex,
          )?.duration;

          // find the first clip with highest ranking score that matches the wildcard spot
          const matchingClip = group.clips.find((clip) => {
            return (
              !checkedClips.includes(clip.elementId) &&
              clip.segment.durationMilliseconds / 1000 >= duration
            );
          });

          if (matchingClip) w.clipId = matchingClip.elementId;
        }

        // if wildcard has selected clip – check if it still exists (in case clips were deleted)
        else if (
          w.clipId &&
          !group.clips.find((clip) => w.clipId === clip.elementId)
        )
          w.clipId = null;

        // update array of checked clips ids
        if (w.clipId) checkedClips.push(w.clipId);
      });

      clipSelectionsTemp.push(foundSelections);
    });

    setClipSelections(clipSelectionsTemp);
  }, [isAnalysing, clipGroups, requiredShots, selectionsLocal]);

  // clip groups – get complete people, incomplete people from most filled to least, and generated people
  const { completePeople, incompletePeople, generatedPeople } = useMemo(() => {
    let completePeople = [];
    let incompletePeople = [];
    let generatedPeople = [];

    clipGroups?.forEach((group) => {
      if (generatedPeopleIds.includes(group.face.elementId))
        generatedPeople.push(group);
      else {
        group.face.missingClips = [];

        group.selectedClips = clipSelections.find(
          (s) => s.faceId === group.face.elementId,
        )?.wildcardsFiller;

        group.selectedClips?.forEach((clip) => {
          if (!clip.clipId) {
            const missingClip = requiredShots.find(
              (shot) => shot.shotIndex === clip.wildCardShotIndex,
            );
            if (missingClip) group.face.missingClips.push(missingClip);
          }
        });

        if (isEmpty(group.face.missingClips)) completePeople.push(group);
        else incompletePeople.push(group);
      }
    });

    incompletePeople.sort(
      (one, other) =>
        one.face.missingClips.length - other.face.missingClips.length,
    );

    return { completePeople, incompletePeople, generatedPeople };
  }, [clipGroups, clipSelections, generatedPeopleIds, requiredShots]);

  const getAssetsError = (type, url) => {
    return {
      heading: `No ${type} found`,
      subheading: `Please return to previous step and upload the ${type}`,
      btn: (
        <Button
          className="w-full text-capitalize"
          onClick={() => navigate(`/${eventId}${url}`)}
        >
          Upload {type}
        </Button>
      ),
    };
  };

  const fetchHlReelData = async (didMount) => {
    try {
      const id = isDemo ? demoData.event.eventId : eventId;

      const { hasTemplate, videoTemplate, requiredShots } =
        await getTemplateData(id);

      if (hasTemplate) {
        setHlReel(videoTemplate);
        setRequiredShots(requiredShots);

        // if processing – update state (it re-fetches every 10 seconds)
        setIsHlReelProcessing(true);

        // highlight reel has processed data
        if (videoTemplate) {
          const hasAllFrames = videoTemplate.shots.every(
            (shot) => shot.type !== 'shot' || !!shot.frameId,
          );

          // if thumbnails for all shots are generated – the highlight reel is fully processed
          if (hasAllFrames) {
            setIsHlReelProcessing(false);

            // if page didn't just mount – show success toast
            if (!didMount)
              toast.success('The highlight reel is processed!', {
                toastId: 'highlight-reel-processing-success',
              });
          }
        }
      }

      // if no highlight reel – show error
      else setError(getAssetsError('highlight reel', URL.VB_HL_REEL));
    } catch (err) {
      console.error(`Error fetching the highlight reel: ${err}`);
      setError({ subheading: 'Error fetching the highlight reel' });
    }
  };

  const fetchPeopleData = async () => {
    try {
      // demo event
      if (isDemo) {
        const selectedPeople = getDemoSelected(demoData.people);
        const generatedPeopleIds = selectedPeople
          .filter((person) => person.isGenerated)
          .map((person) => person.face.elementId);

        setGeneratedPeopleIds(generatedPeopleIds);
      }

      // usual event
      else {
        const generatedPeople = await getCreationResults(eventId);

        setGeneratedPeopleIds(
          generatedPeople.map((person) => person.face.elementId),
        );
      }
    } catch (err) {
      console.error(`Error fetching the people: ${err}`);
      setError({ subheading: 'Error fetching the people' });
    }
  };

  const fetchClipsData = async (didMount) => {
    try {
      const id = isDemo ? demoData.event.eventId : eventId;

      const { stage, selects, clipGroups } = await getClipGroups(
        id,
        deletingClips,
        isDemo,
      );

      setClips(selects);
      setClipGroups(clipGroups);

      switch (stage) {
        case 'processing':
          // if processing – update state (it re-fetches every 10 seconds)
          setIsClipsProcessing(true);
          break;

        case 'finished':
          // if all clips are processed – update state
          setIsClipsProcessing(false);

          // if page didn't just mount – show success toast
          if (!didMount)
            toast.success('All clips are processed!', {
              toastId: 'clips-processing-success',
            });
          break;

        // if no clips – show error
        case 'no-selects':
          setError(getAssetsError('clips', URL.VB_CLIPS));
          break;

        // if no people – show error
        case 'no-faces':
          setError(getAssetsError('people', URL.VB_PEOPLE));
          break;

        default:
          break;
      }
    } catch (err) {
      console.error(`Error fetching the clips: ${err}`);
      setError({ subheading: 'Error fetching the clips' });
    }
  };

  const getFilteredClipSelections = () => {
    const filteredClipSelections = [];

    clipSelections.forEach((group) => {
      // if group has all shots add it to return array
      if (group.wildcardsFiller.every((clip) => clip.clipId))
        filteredClipSelections.push(group);
    });

    return filteredClipSelections;
  };

  const startDemoGeneration = () => {
    clipSelections.forEach((group) => {
      const person = demoData.people.find(
        (p) => p.face.elementId === group.faceId,
      );

      // if creation is already 'generated' – don't alter it
      if (person.isGenerated) return;

      const creationData = demoData.creations[group.faceId];

      // select creation based on selected clips
      creationData.forEach((creation) => {
        let isMatching = true;

        group.wildcardsFiller.forEach((wildcard) => {
          if (
            creation.matchingClips[wildcard.wildCardShotIndex] !==
            wildcard.clipId
          ) {
            isMatching = false;
            return;
          }
        });

        creation.isSelected = isMatching;
      });

      // set person as complete/incomplete depending on whether they have a matching creation
      person.isComplete = !!creationData.find(
        (creation) => creation.isSelected,
      );
    });

    demoData.event.stage = 'generation';
    demoData.isVideosGenerated = false;
    localStorage.setItem('demoData', JSON.stringify(demoData));
  };

  const onSuccessfulGenerationStart = () => {
    trackGenerationStartSuccess(currentEvent);

    // remove eventId from generatedProjects (for GA)
    const generatedProjects =
      JSON.parse(localStorage.getItem('generatedProjects')) ?? [];
    const projectsUpd = generatedProjects.filter((id) => id !== eventId);
    localStorage.setItem('generatedProjects', JSON.stringify(projectsUpd));

    navigate(`/${eventId}${URL.VIDEO_BUILDER}`);
  };

  const onClickGenerate = async () => {
    setIsSubmitting(true);

    storeClipSelections(clipSelections);

    // demo event
    if (isDemo) {
      startDemoGeneration();

      setTimeout(() => {
        onSuccessfulGenerationStart();

        setIsSubmitting(false);
      }, 500);
    }

    // usual event
    else {
      const data = {
        eventId: eventId,
        clipSelections: getFilteredClipSelections(),
      };

      await generateCreations(data)
        .then(async () => {
          onSuccessfulGenerationStart();

          await updateCurrentEvent();
        })
        .catch((err) => {
          trackGenerationStartError(err.message);

          toast.error(err.message);
        });

      setIsSubmitting(false);
    }
  };

  // person opened for clip re-selection
  const openedPerson = useMemo(() => {
    const foundPerson = clipGroups?.find(
      (person) => person.face.elementId === openedPersonId,
    );

    return foundPerson ?? null;
  }, [clipGroups, openedPersonId]);

  const { heading, tipText, alertText, progress, numOfPeople, allowGenerate } =
    getAnalysisPageText({
      currentEvent,
      hlReel,
      requiredShots,
      clips,
      completePeople,
      incompletePeople,
      generatedPeople,
      peopleCount: clipGroups.length,
    });

  const handleTakeMeBack = () => {
    navigate(`/${eventId}${URL.VIDEO_BUILDER}`);

    trackClickTakeMeBack('generation', currentEvent);
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Analysis – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn />

      <div className="flex flex-col gap-8">
        <PageHeader heading={heading} />

        {alertText && (
          <Alert alertData={{ variant: 'warning', text: alertText }} />
        )}

        {isAnalysing && !error ? (
          <React.Fragment>
            <SellingPoint text={tipText} />

            <div className="flex flex-col gap-4 text-center">
              <ProgressBar progress={progress} />

              <div className="font-bold uppercase">Analysing</div>
            </div>

            <ClipsBlocks
              clips={clips}
              deletingClips={deletingClips}
              setDeletingClips={setDeletingClips}
              fetchClipsData={fetchClipsData}
              peopleCount={clipGroups.length}
              isShowSuccessfulClips
            />

            <HlReelPreviewContainer
              hlReel={hlReel}
              requiredShots={requiredShots}
              showVideo
            />

            <DoneBtn
              isBack
              subheading="Don't worry, we'll continue analysing"
            />
          </React.Fragment>
        ) : hlReel && !isEmpty(clips) ? (
          <React.Fragment>
            {tipText && <SellingPoint text={tipText} />}

            <PeopleBlocks
              event={currentEvent}
              completePeople={completePeople}
              incompletePeople={incompletePeople}
              generatedPeople={generatedPeople}
              requiredShots={requiredShots}
              setOpenedPersonId={setOpenedPersonId}
              isDemo={isDemo}
            />

            <ClipsBlocks
              clips={clips}
              deletingClips={deletingClips}
              setDeletingClips={setDeletingClips}
              fetchClipsData={fetchClipsData}
            />

            <HlReelPreviewContainer
              hlReel={hlReel}
              requiredShots={requiredShots}
            />

            {allowGenerate ? (
              <div className="w-full flex flex-col items-center gap-4">
                <div className="flex flex-col items-center text-center gap-2">
                  <div className="text-lg font-bold">
                    Ready to generate personalised highlight reels?
                  </div>
                  <div className="italic">
                    We'll generate reels for {numOfPeople} checked-in people.
                  </div>
                </div>

                <FancyBtn
                  text="Generate"
                  isSubmitting={isSubmitting}
                  onClick={onClickGenerate}
                />

                <div
                  className="underline cursor-pointer hover:text-primary-900"
                  onClick={handleTakeMeBack}
                >
                  Not yet, take me back
                </div>
              </div>
            ) : (
              <DoneBtn isBack />
            )}
          </React.Fragment>
        ) : null}

        <PersonClipsModal
          clipGroups={(completePeople || []).concat(incompletePeople || [])}
          openedPerson={openedPerson}
          hlReel={hlReel}
          requiredShots={requiredShots}
          clipSelections={clipSelections}
          setClipSelections={setClipSelections}
          openedPersonId={openedPersonId}
          setOpenedPersonId={setOpenedPersonId}
        />
      </div>

      <ErrorModal
        show={!!error}
        heading={error?.heading ?? 'Something went wrong'}
        subheading={error?.subheading}
        btn={error?.btn}
        isTryAgainBtn={!error?.btn}
      />
    </PageSpinner>
  );
};

export default Analysis;
