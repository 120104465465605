import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

import FormFeedback from '../../../components/form/formFeedback';

const EventDatesSelector = (props) => {
  const { startDate, endDate, onChangeStartDate, onChangeEndDate, errors } =
    props;

  return (
    <div className="flex flex-col sm:flex-row gap-6 sm:gap-10">
      <FloatingLabel label="Start Date*" className="w-full sm:w-1/2">
        <Form.Control
          type="date"
          name="startDate"
          value={startDate}
          onChange={onChangeStartDate}
          placeholder="dd/mm/yyyy"
          isInvalid={errors.startDate}
          required
        />

        <FormFeedback error={errors.startDate} />

        <Form.Text muted>
          You won't be able to edit the project after it starts
        </Form.Text>
      </FloatingLabel>

      <FloatingLabel label="End Date*" className="w-full sm:w-1/2">
        <Form.Control
          type="date"
          name="endDate"
          value={endDate}
          onChange={onChangeEndDate}
          placeholder="dd/mm/yyyy"
          isInvalid={errors.endDate}
          required
        />

        <FormFeedback error={errors.endDate} />

        <Form.Text muted>
          Attendees won't be able to check in after the project ends
        </Form.Text>
      </FloatingLabel>
    </div>
  );
};

export default EventDatesSelector;
