import React from 'react';
import { PiInfoFill } from 'react-icons/pi';

import PopoverTrigger from '../../../components/popoverTrigger';

const HeadingWithInfo = (props) => {
  const { heading, subheading, infoHeader, infoBody } = props;

  const popover = (infoHeader || infoBody) && (
    <PopoverTrigger
      content={
        <span className="leading-none">
          <PiInfoFill
            className="hover:fill-[url('#icon-primary-gradient')] cursor-pointer p-0"
            size="1.2em"
          />
        </span>
      }
      placement="bottom"
      addClass="big-popover"
      popoverHeader={infoHeader}
      popoverBody={<div className="flex flex-col gap-2">{infoBody}</div>}
    />
  );

  if (heading)
    return (
      <h4 className="flex items-center gap-1">
        <div className="font-semibold">{heading}</div>

        {popover}
      </h4>
    );

  if (subheading)
    return (
      <h5 className="flex items-center gap-1">
        <div className="font-semibold">{subheading}</div>

        {popover}
      </h5>
    );

  return null;
};

export default HeadingWithInfo;
