import React, { useState, useEffect } from 'react';

import { Button } from '../../../components';
import FormFeedback from '../../../components/form/formFeedback';

import { getEventTypes } from '../../../services/api/events';

const EventTypeSelector = (props) => {
  const { type, changeType, error } = props;

  const [recType, setRecType] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);

  const volumeOptions = [
    { text: '15', recType: 'videographer' },
    { text: '150', recType: 'videographer' },
    { text: '1,500', recType: 'brand' },
    { text: '15,000 – 150,000', recType: 'brand' },
  ];

  useEffect(() => {
    const onMount = async () => {
      const options = await getEventTypes();
      setTypeOptions(options.filter((o) => o !== 'demo'));
    };

    onMount();
  }, []);

  return (
    <div>
      {!type ? (
        <React.Fragment>
          <div>How many people are attending your event?</div>

          <div className="flex flex-wrap gap-4">
            {volumeOptions.map((option, index) => (
              <Button
                variant="outline"
                className="w-[calc((100%_-_1rem)_/_2)] sm:w-[calc((100%_-_3rem)_/_4)]"
                onClick={() => {
                  sessionStorage.setItem('attendees', option.text);
                  changeType(option.recType);
                  setRecType(option.recType);
                }}
                key={index}
              >
                ~ {option.text}
              </Button>
            ))}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {recType && (
            <div>
              Based on the given attendee number, we recommend you to use{' '}
              <b>{recType}</b> type.
            </div>
          )}

          <div className="flex flex-col sm:flex-row gap-2 sm:gap-10">
            {typeOptions.map((option, index) => (
              <Button
                variant={type === option ? 'pink' : 'outline'}
                className="w-full sm:w-1/2 text-capitalize"
                onClick={() => changeType(option)}
                key={index}
              >
                {option}
              </Button>
            ))}
          </div>
        </React.Fragment>
      )}

      <FormFeedback error={error} />
    </div>
  );
};

export default EventTypeSelector;
