import React from 'react';
import classNames from 'classnames';

const SideNavBigBtn = (props) => {
  const { onClick, icon, currentIcon, text, isCurrent, index } = props;

  return (
    <button
      id={`project-sidenav-tab-${index}`}
      aria-controls={`project-sidenav-tabpanel-${index}`}
      onClick={onClick}
      className="group w-14 flex flex-col items-center justify-center [&:not(&:first-child)]:pt-2 [&:not(&:last-child)]:pb-2 gap-1.5 text-center"
    >
      <div
        className={classNames(
          'w-8 h-8 flex items-center justify-center p-1 text-xl leading-none rounded-md group-hover:bg-grey-100 group-hover:shadow-md',
          { 'bg-grey-100 shadow-md': isCurrent },
        )}
      >
        {isCurrent ? currentIcon : icon}
      </div>

      <div className="text-xs font-medium leading-none">{text}</div>
    </button>
  );
};

export default SideNavBigBtn;
