import config from './config';

const { baseS3BucketPrefix } = config;

// routes
export const URL = {
  HOME: '/',

  ENTER_EMAIL: '/enter-email',
  EMAIL_VERIFICATION: '/email-verification',
  CREATE_ACCOUNT: '/create-account',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',

  VIDEO_BUILDER: '/video-builder',
  VB_ASSIGNEE: '/video-builder/assignee',
  VB_PEOPLE: '/video-builder/people',
  VB_HL_REEL: '/video-builder/highlight-reel',
  VB_CLIPS: '/video-builder/clips',
  VB_ANALYSIS: '/video-builder/analysis',
  VB_SHARE: '/video-builder/share',

  SHARE: '/share/',
  CHECK_IN: '/events/',
  CHECK_IN_STAGE: '/complete-check-in',

  REVIEW_VIDEO: '/review-video/',
  REVIEW_VIDEO_GENERATING: '/review-video/generating/',

  PROJECT_SETTINGS: '/settings',

  DASH_BRAND: '/brand',
  DASH_ANALYTICS: '/analytics',

  PRICING: '/pricing',

  HELP: '/help',

  MANAGED_SERVICES: '/managed-services/',

  PAYMENT_REDIRECT: '/payment-redirect',
};

export const S3_BUCKET_URL = `https://s3.ap-southeast-2.amazonaws.com/${baseS3BucketPrefix}`;

export const DEMO_ID = {
  FIFA: 'demo-fifa',
};

// pricing page sections
export const PRICING_ID = {
  SUBSCRIPTION: 'subscription',
  EVENTS: 'events-packs',
  REPORTS: 'reports-packs',
};

// event types
export const EVENT_TYPE_DEMO = 'demo';
export const EVENT_TYPE_VIDEOGRAPHER = 'videographer';
export const EVENT_TYPE_BRAND = 'brand';

// password length
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;

// email sending
export const RESEND_EMAIL_IN_SEC = 60;
export const LINK_EXPIRE_IN_MIN = 60;

// upload selects limit
export const SELECTS_UPLOAD_LIMIT = Infinity;
export const MAX_FILE_SIZE_MB = Infinity;

// recommended wildcards
export const REC_WILDCARDS = [5, 5];

export const STRIPE_PRODUCTS = {
  REMOVE_WATERMARK_PROJECT: 'remove-watermark-project',
  REMOVE_WATERMARK_ATTENDEE: 'remove-watermark-attendee',
  ATTENDEE_VIDEO_DONATION: 'attendee-video-donation',
};

// promo codes
export const LAUNCH_PROMO_CODE = 'WELCOMECROWD';
export const FIRST_EVENT_PROMO_CODE = 'FIRSTEVENT2024';

// email templates ID
export const EMAILS = {
  // attendee
  CHECK_IN_VIDEOGRAPHER: 'd-2629c095bcc4428cb89bc0dd01bc1e5b',
  CHECK_IN_BRAND: 'd-8c5dbf506ddc44eab24aad61946c384a',
  CHECK_IN_ATTENDEE_REVIEW: 'd-6ba85c39ee7248a6bc72a295433c3120',
  UGC_RECEIVED: 'd-aacf5693ae434c4b83565d7ccec22a6c',
  REVIEW_VIDEO: 'd-41f979cd66084c26aeda605cc8a21482',
  CREATION_SHARE: 'd-276b39cac62648cd8590744e61587b52',
  CREATION_AWARENESS: 'd-57766c6d6ea947b3a234964fc841589c',
  CREATION_REMOVE_WATERMARK: 'd-6b55ab1a567c436e8e21ce23db905c1e',
  CREATION_DONATE: 'd-e8b7efec4e5d4d45b87b142ee03df8ee',
  CREATION_CUSTOM: 'd-11a2b1f67186425aa53072670291e50f',
  MISSING_ASSETS: 'd-1bda33e682494921a9830e77b83cbfc8',

  // user
  MS_NOTIFICATION: 'd-32f5c684c89143898c87c7962d4fb02f',
  MS_CONFIRMATION: 'd-ad58c0d110a1485485e0d8f62e5244a7',
  MOBILE_REMINDER: 'd-867faf5896d84d338ba176d6917e5600',
  VB_STEP_COMPLETE: 'd-01e919e7839745ceba6fbeb79ece8517',
  ASSIGNEE_INVITE: 'd-ab8f6ad9c6ca43d7b4a206c4e0cc7c2e',
};

// email lists ID
export const EMAIL_LISTS = {
  MOBILE_SIGN_IN: '9c76f100-ab6e-4b64-9a20-8ce6cc826f6b',
};

export const MS_RECIPIENTS = [
  'hello@crowdclip.com',
  'charlotte@crowdclip.com',
  'kenneth@crowdclip.com',
];

export const DEMO_ACC = 'naomi@crowdclip.com';

export const HELP_VIDEO_ID = {
  ONBOARDING_DESKTOP: 'rwOBnk2h5z0',
  ONBOARDING_MOBILE: '4JpgKQPfWWE',
};

// zip files
export const ZIP_URL = {
  QUICKSTART:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/Quickstart+CrowdClip.zip',
  FIFA_TEMPLATE_ZIP:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/fifa-template.zip',
  FIFA_FACES_ZIP:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/fifa-faces.zip',
  FIFA_SELECTS_ZIP:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/fifa-selects.zip',
  WEDDING_TEMPLATE_ZIP:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/wedding-template.zip',
  WEDDING_FACES_ZIP:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/wedding-faces.zip',
  WEDDING_SELECTS_ZIP:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/zip-files/wedding-selects.zip',
};

export const TEMPLATE_EXAMPLE_DOWNLOAD_LINKS = {
  ADOBE_LINK:
    'https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/faq-videos/download-links/crowdclip-project-template.prproj',
};

export const FACE_EMAIL_STATUS = {
  NOT_SENT: 'not-sent',
  SENDING: 'sending',
  SENT: 'sent',
  RESEND: 'resend',
};

export const UPLOAD_TYPES = {
  PROGRESS: 'progress',
  ABORT: 'abort',
  LOADEND: 'loadend',
  UPLOADED: 'uploaded',
  ERROR: 'error',
};

export const BRAND_CI_STEPS = {
  DETAILS_STARTED: 'details-started',
  DETAILS_ENDED: 'details-ended',
  PHOTO_STARTED: 'photo-started',
  PHOTO_ENDED: 'photo-ended',
  NO_PHOTO: 'no-photo',
  ERROR: 'error',
};

// user guiding ids
export const UG_ID = {
  ONBOARDING_MARKETER: 118968,
  ONBOARDING_VIDEOGRAPHER: 118976,
};

export const FORMSPARK_ID = {
  NEXT_EVENT: '6WNfuSEK3',
};

// uncomment 1 depending on what website is tested
export const TESTING_BASE_URL = 'http://localhost:3000';
// export const TESTING_BASE_URL = 'https://stg-app.crowdclip.com';
// export const TESTING_BASE_URL = 'https://app.crowdclip.com';
