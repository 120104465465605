import React from 'react';
import { Link } from 'react-router-dom';

import { getUrl, isEmpty } from '../../../../Utils';
import { URL, EMAILS } from '../../../../constants';
import { getTemplate } from '../../../../services/api/emails';
import { Event, SGTemplate } from '../../../../types';

export interface EmailHubTemplate {
  templateId: string;
  title: string;
  description: string | React.JSX.Element;
  selectionUrl?: string;
  show?: boolean;
  isAutomatic?: boolean;
  isOptional?: boolean;
  isCustom?: boolean;
  isComingSoon?: boolean;
}

export const fetchTemplates = async (
  emails: {}[],
): Promise<{ [key: string]: SGTemplate }> => {
  const fetchedTemplates: { [key: string]: SGTemplate } = {};

  const fetchPromises = emails.map(async (email: any) => {
    const templateId: string = email.templateId;

    if (!templateId) return;

    const template: SGTemplate = await getTemplate(templateId);
    fetchedTemplates[templateId] = template;
  });

  await Promise.all(fetchPromises);

  return fetchedTemplates;
};

export const getAllEmails = (event: Event) => {
  if (isEmpty(event)) return [];

  const eventId: string = event.eventId;
  const isAttendeeReview: boolean =
    event.attendeeReviewData?.isAttendeeReview ?? false;
  const isBrand: boolean = event.type === 'brand';

  const checkInTemplateId = isAttendeeReview
    ? EMAILS.CHECK_IN_ATTENDEE_REVIEW
    : isBrand
      ? EMAILS.CHECK_IN_BRAND
      : EMAILS.CHECK_IN_VIDEOGRAPHER;

  const emails: EmailHubTemplate[] = [
    {
      templateId: checkInTemplateId,
      title: 'Check-In Welcoming Email',
      description: 'Is sent automatically when an attendee check-in',
      show: true,
      isAutomatic: true,
    },
    {
      templateId: EMAILS.UGC_RECEIVED,
      title: 'UGC Received Email',
      description: 'Is sent automatically when an attendee uploads their UGC',
      show: isBrand,
      isAutomatic: true,
    },
    {
      templateId: EMAILS.REVIEW_VIDEO,
      title: 'Review Video Email',
      description: 'Is sent automatically when all assets are analysed',
      show: isAttendeeReview,
      isAutomatic: true,
    },
    {
      templateId: event.emailsData?.creationTemplateId ?? '',
      title: isAttendeeReview ? 'Follow-Up Email' : 'Video Creation Email',
      description: (
        <>
          Is sent manually from the{' '}
          <Link to={getUrl(URL.VB_SHARE, eventId)}>Share</Link> page after
          videos are generated
        </>
      ),
      selectionUrl: getUrl(URL.PROJECT_SETTINGS, eventId, 'email-hub/creation'),
      show: true,
    },
    {
      templateId: EMAILS.MISSING_ASSETS,
      title: 'Missing Assets Email',
      description: (
        <>
          Is sent manually from the{' '}
          <Link to={getUrl(URL.VB_ANALYSIS, eventId)}>Analysis</Link> page to
          ask attendees with missing clips to fill in the gaps with their videos
        </>
      ),
      show: !isAttendeeReview,
      isOptional: true,
    },
  ];

  return emails;
};

export const getSelectionEmails = (type?: string) => {
  if (type === 'creation') return creationTemplates;
  else return [];
};

export const creationTemplates: EmailHubTemplate[] = [
  {
    templateId: EMAILS.CREATION_SHARE,
    title: 'Share Only',
    description: 'Encourage your attendees to just share their video',
  },
  {
    templateId: EMAILS.CREATION_AWARENESS,
    title: 'Spread Awareness',
    description:
      'Encourage your attendees to share their video and spread awareness of your organisation',
  },
  {
    templateId: EMAILS.CREATION_REMOVE_WATERMARK,
    title: 'Remove Watermark & Share',
    description:
      'Use if opted-in for attendees to pay to remove watermark from their videos',
  },
  {
    templateId: EMAILS.CREATION_DONATE,
    title: 'Share & Donate',
    description: 'Use if opted-in for donations from attendees',
  },
  {
    templateId: EMAILS.CREATION_CUSTOM,
    title: 'Custom Text',
    description: 'Customise the email text to your needs',
    isCustom: true,
  },
];
