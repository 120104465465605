import React, { useState, useEffect } from 'react';

import { isPlural } from '../../../Utils';

import FormFeedback from '../../../components/form/formFeedback';
import SideIcon from '../../../components/icons/sideIcon';
import PopoverTrigger from '../../../components/popoverTrigger';
import TemplateShotBlock from '../../../components/templateShotBlock';

const EventWildcardsSelector = (props) => {
  const { wildcards, onChangeWildcards, error } = props;

  const [editIndex, setEditIndex] = useState(-1);
  const [editText, setEditText] = useState('');

  // clear the edit index when clicked outside of wildcard
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editIndex !== -1) {
        const element = document.getElementById(`${editIndex}-wildcard`);
        if (!element?.contains(event.target)) setEditIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editIndex]);

  const handleAdd = () => {
    const wildcardsTemp = [...wildcards, 5];
    onChangeWildcards(wildcardsTemp);
  };

  const handleDelete = (index) => {
    const wildcardsTemp = [...wildcards];
    wildcardsTemp.splice(index, 1);
    onChangeWildcards(wildcardsTemp);

    setEditIndex(-1);
  };

  const handleEdit = (index) => {
    if (editIndex === -1) {
      setEditIndex(index);
      setEditText(wildcards[index]);
    } else {
      setEditIndex(-1);
      setEditText('');
    }
  };

  const onChangeEditText = (e) => {
    const wildcardsTemp = [...wildcards];
    wildcardsTemp[editIndex] = e.target.value;
    onChangeWildcards(wildcardsTemp);

    setEditText(e.target.value);
  };

  return (
    <div>
      <div className="flex flex-wrap items-center gap-2">
        <TemplateShotBlock type="selects" />

        {wildcards.map((wildcard, index) => (
          <React.Fragment key={index}>
            <TemplateShotBlock type="wildcard" id={`${index}-wildcard`}>
              <div className="absolute w-full h-full">
                <SideIcon icon="edit" onClick={() => handleEdit(index)} />

                {wildcards.length > 1 && (
                  <SideIcon icon="delete" onClick={() => handleDelete(index)} />
                )}
              </div>

              <h6 className="text-sm font-bold">Wildcard</h6>

              {index === editIndex ? (
                <input
                  className="w-[70%] h-[1.5em] p-0 text-center text-sm border-x-0 border-t-0 border-b-1 border-grey-200 border-solid focus:border-grey-200 z-[3]"
                  type="number"
                  name="editText"
                  value={editText}
                  onChange={onChangeEditText}
                  placeholder="0"
                  required
                />
              ) : (
                <div className="text-sm">
                  {wildcard} second{isPlural(parseFloat(wildcard))}
                </div>
              )}
            </TemplateShotBlock>

            <TemplateShotBlock
              type="selects"
              className={index === wildcards.length - 1 ? 'mr-8' : ''}
            />
          </React.Fragment>
        ))}

        <PopoverTrigger
          content={
            <div
              className="w-[6rem] sm:w-[7rem] h-[calc(6rem_*_9_/_16)] sm:h-[calc(7rem_*_9_/_16)] flex flex-col items-center justify-center rounded-md border-1 border-grey-900 border-solid text-2xl cursor-pointer hover:text-primary-900 hover:border-primary-900"
              onClick={handleAdd}
            >
              +
            </div>
          }
          hidePopover={wildcards.length < 3}
          placement="top"
          popoverBody="We don't recommend using more than 3 wildcards"
        />
      </div>

      <FormFeedback error={error} />
    </div>
  );
};

export default EventWildcardsSelector;
