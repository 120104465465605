import React, { useState, useEffect, useMemo } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { PiRepeat, PiPencil } from 'react-icons/pi';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { isEmpty, getUrl, getDemoSelected } from '../../../Utils';

import {
  trackQuickstartPackDownload,
  trackDemoStartAgain,
  trackEditProject,
  trackAnalysisFinish,
  trackGenerationFinish,
} from '../../../analytics';

import ContentHeader from '../../../components/contentHeader';
import ErrorModal from '../../../components/modals/errorModal';
import PageSpinner from '../../../components/pageSpinner';
import SellingPoint from '../../../components/sellingPoint';

import { URL, DEMO_ID, ZIP_URL } from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { getUploadedFaces } from '../../../services/api/faces';
import { getTemplateData } from '../../../services/api/template';
import {
  getUploadedSelects,
  getClipGroups,
} from '../../../services/selectGroupsService';

import { useEventStore } from '../../../stores/event';

import { demoFifa } from '../components/demoData';
import CreateStep from './components/createStep';
import SelectStep from './components/selectStep';
import SetDateStep from './components/setDateStep';
import ShareStep from './components/shareStep';
import WaitStep from './components/waitStep';

const VideoBuilder = () => {
  const { eventId } = useParams();

  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);
  const currentEventLocal = useEventStore((state) => state.currentEvent);
  const selectionsLocal = useEventStore((state) => state.clipSelections);

  // select
  const uploadData = JSON.parse(localStorage.getItem('uploadData'));

  const [isPeopleUploaded, setIsPeopleUploaded] = useState(false);
  const [uploadedPeople, setUploadedPeople] = useState([]);

  const [isHlReelUploaded, setIsHlReelUploaded] = useState(false);

  const [isClipsUploaded, setIsClipsUploaded] = useState(false);
  const [uploadedClips, setUploadedClips] = useState([]);

  // analysis
  const [clipSelections, setClipSelections] = useState([]);

  const [clipGroups, setClipGroups] = useState([]);
  const [generatedPeopleIds, setGeneratedPeopleIds] = useState([]);

  const [isHlReelProcessed, setIsHlReelProcessed] = useState(false);
  const [hlReel, setHlReel] = useState(null);
  const [requiredShots, setRequiredShots] = useState([]);

  const [isClipsProcessed, setIsClipsProcessed] = useState(false);
  const [processingClips, setProcessingClips] = useState(null);

  // general
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  // demo data
  const demoDataLocal = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const [demoData, setDemoData] = useState(demoDataLocal);
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const currentEvent = isDemo ? demoData.event : currentEventLocal;

  const isAttendeeReview = useMemo(
    () => currentEvent?.attendeeReviewData?.isAttendeeReview,
    [currentEvent?.attendeeReviewData?.isAttendeeReview],
  );

  const navigate = useNavigate();

  // clip groups – get complete faces, incomplete faces from most filled to least, and generated faces
  const { completePeople, incompletePeople, generatedPeople } = useMemo(() => {
    let completePeople = [];
    let incompletePeople = [];
    let generatedPeople = [];

    clipGroups?.forEach((group) => {
      if (generatedPeopleIds.includes(group.face.elementId))
        generatedPeople.push(group);
      else {
        group.face.missingClips = [];

        group.selectedClips = clipSelections.find(
          (s) => s.faceId === group.face.elementId,
        )?.wildcardsFiller;

        group.selectedClips?.forEach((clip) => {
          if (!clip.clipId) {
            const missingClip = requiredShots.find(
              (shot) => shot.shotIndex === clip.wildCardShotIndex,
            );
            if (missingClip) group.face.missingClips.push(missingClip);
          }
        });

        if (isEmpty(group.face.missingClips)) completePeople.push(group);
        else incompletePeople.push(group);
      }
    });

    incompletePeople.sort(
      (one, other) =>
        one.face.missingClips.length - other.face.missingClips.length,
    );

    return { completePeople, incompletePeople, generatedPeople };
  }, [clipGroups, generatedPeopleIds, clipSelections, requiredShots]);

  const isAssetsUploaded = useMemo(() => {
    localStorage.setItem(
      'uploadData',
      JSON.stringify({
        eventId,
        peopleCount: uploadedPeople.length,
        isHlReelUploaded,
        clipsCount: uploadedClips.length,
        rejectedAnalysis:
          uploadData?.eventId === eventId ? uploadData.rejectedAnalysis : false,
      }),
    );

    return isPeopleUploaded && isHlReelUploaded && isClipsUploaded;
  }, [eventId, isPeopleUploaded, isClipsUploaded, isHlReelUploaded]);

  const isAnalysisStarted = useMemo(() => {
    return currentEvent?.stage === 'analysis';
  }, [currentEvent?.stage]);

  const isAssetsProcessed = useMemo(() => {
    return (
      (isAssetsUploaded && isClipsProcessed && isHlReelProcessed) ||
      (isDemo && demoData.isAssetsProcessed)
    );
  }, [
    isAssetsUploaded,
    isClipsProcessed,
    isHlReelProcessed,
    isDemo,
    demoData.isAssetsProcessed,
  ]);

  const isGenerationStarted = useMemo(() => {
    return currentEvent?.stage === 'generation';
  }, [currentEvent?.stage]);

  const isVideosGenerated = useMemo(() => {
    return (
      (isGenerationStarted && isEmpty(completePeople)) ||
      (isDemo && demoData.isVideosGenerated)
    );
  }, [completePeople, isGenerationStarted, isDemo, demoData.isVideosGenerated]);

  // when page loads – reset loading & errors states
  const resetPageStates = () => {
    setIsLoading(true);
    setPageError(null);
    setError(null);
  };

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      resetPageStates();

      if (currentEvent.error) {
        setPageError(currentEvent.error);
        setIsLoading(false);
        return;
      }

      await fetchPeopleData();
      await fetchHlReelData();
      await fetchClipsData();

      setIsLoading(false);
    };

    const onDemoMount = async () => {
      resetPageStates();

      fetchDemoUploadData();

      if (demoData.isAssetsProcessed) await fetchDemoAnalysisData();

      setIsLoading(false);
    };

    if (currentEvent) {
      if (isDemo) onDemoMount();
      else onMount();
    }
  }, [currentEvent]);

  // if highlight reel is processing – re-fetch every 10 seconds
  useEffect(() => {
    if (isAnalysisStarted && !isHlReelProcessed && !isDemo) {
      const hlReelInterval = setInterval(async () => {
        await fetchHlReelData();
      }, 10000);

      return () => clearInterval(hlReelInterval);
    }
  }, [eventId, isAnalysisStarted, isHlReelProcessed]);

  // if clips are processing – re-fetch every 10 seconds
  useEffect(() => {
    if (isAnalysisStarted && !isClipsProcessed && !isDemo) {
      const clipsInterval = setInterval(async () => {
        await fetchClipsData();
      }, 10000);

      return () => clearInterval(clipsInterval);
    }
  }, [eventId, isAnalysisStarted, isClipsProcessed]);

  // if videos are generating – re-fetch every 10 seconds
  useEffect(() => {
    if (isGenerationStarted && !isVideosGenerated && !isDemo) {
      const generationInterval = setInterval(async () => {
        await fetchPeopleData();
      }, 10000);

      return () => clearInterval(generationInterval);
    }
  }, [eventId, isGenerationStarted, isVideosGenerated]);

  // when analysis finish – add GA event & navigate to Analysis page
  useEffect(() => {
    if (isAnalysisStarted && isAssetsProcessed && !isLoading) {
      const projectsUpd =
        JSON.parse(localStorage.getItem('analysedProjects')) ?? [];

      // check if eventId is not in localStorage analysedProjects – to avoid duplicate events
      if (!projectsUpd.includes(eventId)) {
        trackAnalysisFinish(currentEvent);

        projectsUpd.push(eventId);
        localStorage.setItem('analysedProjects', JSON.stringify(projectsUpd));

        if (!isAttendeeReview) navigate(`/${eventId}${URL.VB_ANALYSIS}`);
      }
    }
  }, [currentEvent, eventId, isAssetsProcessed]);

  // when generation finish – add GA event & navigate to Share page
  useEffect(() => {
    if (isGenerationStarted && isVideosGenerated && !isLoading) {
      const projectsUpd =
        JSON.parse(localStorage.getItem('generatedProjects')) ?? [];

      // check if eventId is not in localStorage generatedProjects – to avoid duplicate events
      if (!projectsUpd.includes(eventId)) {
        trackGenerationFinish(currentEvent);

        projectsUpd.push(eventId);
        localStorage.setItem('generatedProjects', JSON.stringify(projectsUpd));

        navigate(`/${eventId}${URL.VB_SHARE}`);
      }
    }
  }, [currentEvent, eventId, isVideosGenerated]);

  const fetchPeopleData = async () => {
    try {
      // fetch uploaded faces
      const faces = await getUploadedFaces(eventId);

      setIsPeopleUploaded(!isEmpty(faces));
      setUploadedPeople(faces);

      // fetch generated faces
      const generatedPeople = await getCreationResults(eventId);

      setGeneratedPeopleIds(
        generatedPeople.map((person) => person.face.elementId),
      );
    } catch (err) {
      console.error(`Error fetching the people: ${err}`);
      setError('Error fetching the people');
    }
  };

  const fetchHlReelData = async () => {
    try {
      const { hasTemplate, videoTemplate, requiredShots } =
        await getTemplateData(eventId);

      setIsHlReelUploaded(hasTemplate);

      if (hasTemplate && (isAnalysisStarted || isGenerationStarted)) {
        setHlReel(videoTemplate);
        setRequiredShots(requiredShots);

        // if processing – update state (it re-fetches every 10 seconds)
        setIsHlReelProcessed(false);

        // template has processed data
        if (videoTemplate) {
          const hasAllFrames = videoTemplate.shots.every(
            (shot) => shot.type !== 'shot' || !!shot.frameId,
          );

          // if thumbnails for all shots are generated – the template is fully processed
          if (hasAllFrames) setIsHlReelProcessed(true);
        }
      }
    } catch (err) {
      console.error(`Error fetching the highlight reel: ${err}`);
      setError('Error fetching the highlight reel');
    }
  };

  const fetchClipsData = async () => {
    try {
      const uploadedClips = await getUploadedSelects(eventId);

      setIsClipsUploaded(!isEmpty(uploadedClips));
      setUploadedClips(uploadedClips);

      if (isAnalysisStarted || isGenerationStarted) {
        const { stage, selects, clipGroups } = await getClipGroups(eventId, []);

        setProcessingClips(selects);
        setClipGroups(clipGroups);

        switch (stage) {
          // if processing – update state (it re-fetches every 10 seconds)
          case 'processing':
            setIsClipsProcessed(false);
            break;

          // if all selects are processed – update state
          case 'finished':
            setIsClipsProcessed(true);
            break;

          default:
            break;
        }
      }
    } catch (err) {
      console.error(`Error fetching the clips: ${err}`);
      setError('Error fetching the clips');
    }
  };

  const fetchDemoUploadData = (customPeople) => {
    // people
    const selectedPeople = getDemoSelected(customPeople ?? demoData.people);
    const generatedPeopleIds = selectedPeople
      .filter((person) => person.isGenerated)
      .map((person) => person.face.elementId);

    setIsPeopleUploaded(demoData.isPeopleUploaded);
    setUploadedPeople(selectedPeople);
    setGeneratedPeopleIds(generatedPeopleIds);

    // highlight reel
    setIsHlReelUploaded(demoData.isHlReelUploaded);
    setHlReel(demoData.hlReel);

    // clips
    const selectedClips = getDemoSelected(demoData.clips);

    setIsClipsUploaded(demoData.isClipsUploaded);
    setUploadedClips(selectedClips);
  };

  const fetchDemoAnalysisData = async () => {
    try {
      const id = demoData.event.eventId;

      const { requiredShots } = await getTemplateData(id);
      const { clipGroups } = await getClipGroups(id, [], true);

      setRequiredShots(requiredShots);
      setClipGroups(clipGroups);
    } catch (err) {
      console.error(`Error fetching the analysis data: ${err}`);
      setError('Error fetching the analysis data');
    }
  };

  const handleUpdateDemoData = (newDemoData) => {
    setDemoData(newDemoData);
    localStorage.setItem('demoData', JSON.stringify(newDemoData));
  };

  const handleDemoAnalysisActions = async (newDemoData, isAfter) => {
    handleUpdateDemoData(newDemoData);

    if (isAfter) fetchDemoAnalysisData();
  };

  const handleDemoGenerationActions = (newDemoData) => {
    handleUpdateDemoData(newDemoData);

    fetchDemoUploadData(newDemoData.people);
  };

  const resetDemo = () => {
    localStorage.setItem('demoData', JSON.stringify(demoFifa));

    window.location.reload();

    trackDemoStartAgain('Video Builder');
  };

  // clip selections – find the matching clips for each person's wildcard spot
  useEffect(() => {
    if (isEmpty(requiredShots) || isEmpty(clipGroups)) return;

    const clipSelectionsTemp = [];

    // for each face group – find the selected clips
    clipGroups.forEach((group) => {
      const groupId = group.face.elementId || null;

      const selectionsObj = {
        faceId: groupId,
        wildcardsFiller: requiredShots.map((shot) => ({
          wildCardShotIndex: shot.shotIndex,
          clipId: null,
        })),
      };

      // find if the face already has selections data or set new object
      const foundSelections =
        selectionsLocal.find((s) => s.faceId === groupId) ?? selectionsObj;

      const checkedClips = [];

      // find matching clips
      foundSelections?.wildcardsFiller.forEach((w) => {
        // if wildcard has NO selected clip –  – find if there's matching one
        if (!w.clipId) {
          const duration = requiredShots.find(
            (shot) => shot.shotIndex === w.wildCardShotIndex,
          )?.duration;

          // find the first clip with highest ranking score that matches the wildcard spot
          const matchingClip = group.clips.find((clip) => {
            return (
              !checkedClips.includes(clip.elementId) &&
              clip.segment.durationMilliseconds / 1000 >= duration
            );
          });

          if (matchingClip) w.clipId = matchingClip.elementId;
        }

        // if wildcard has selected clip – check if it still exists (in case selects were deleted)
        else if (
          w.clipId &&
          !group.clips.find((clip) => w.clipId === clip.elementId)
        )
          w.clipId = null;

        // update array of checked clips ids
        if (w.clipId) checkedClips.push(w.clipId);
      });

      clipSelectionsTemp.push(foundSelections);
    });

    setClipSelections(clipSelectionsTemp);
  }, [isAssetsProcessed, clipGroups, requiredShots, selectionsLocal]);

  const content = (
    <React.Fragment>
      <SelectStep
        peopleCount={uploadedPeople.length}
        clipsCount={uploadedClips.length}
        isPeopleUploaded={isPeopleUploaded}
        isHlReelUploaded={isHlReelUploaded}
        isClipsUploaded={isClipsUploaded}
        isDemo={isDemo}
      />

      <CreateStep
        isDisabled={!isAssetsUploaded}
        uploadedPeopleCount={uploadedPeople.length}
        uploadedClipsCount={uploadedClips.length}
        hlReel={hlReel}
        processingClips={processingClips}
        analyseStatus={
          isAssetsProcessed
            ? 'analysed'
            : isAnalysisStarted
              ? 'analysing'
              : 'not-analysed'
        }
        onAnalysisStart={() => {
          setIsHlReelProcessed(false);
          setIsClipsProcessed(false);
        }}
        requiredShots={requiredShots}
        completePeople={completePeople}
        incompletePeople={incompletePeople}
        generatedPeople={generatedPeople}
        isGenerationStarted={isGenerationStarted}
        isDemo={isDemo}
        handleDemoAnalysisActions={handleDemoAnalysisActions}
        handleDemoGenerationActions={handleDemoGenerationActions}
      />

      <ShareStep peopleCount={generatedPeople.length} />
    </React.Fragment>
  );

  const arContent = (
    <React.Fragment>
      <SetDateStep />

      <SelectStep
        peopleCount={uploadedPeople.length}
        clipsCount={uploadedClips.length}
        isPeopleUploaded={isPeopleUploaded}
        isHlReelUploaded={isHlReelUploaded}
        isClipsUploaded={isClipsUploaded}
      />

      <WaitStep
        isAssetsUploaded={isAssetsUploaded}
        isVideosGenerated={!isEmpty(generatedPeople)}
      />

      <ShareStep peopleCount={generatedPeople.length} />
    </React.Fragment>
  );

  const smallProjectBtnClass =
    'flex items-center justify-center px-2 py-1 gap-1 border-1 leading-none text-primary-900 no-underline border-primary-900 border-solid rounded-full hover:bg-primary-900 hover:text-white hover:opacity-100';

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Video Builder – ${currentEvent?.name}`}
      isPageContainer
      className="items-center gap-8"
    >
      <div className="w-full sm:w-1/2 flex flex-col items-center gap-2 text-center">
        <ContentHeader title={currentEvent?.name} withDemoBadge={isDemo} />

        {isDemo ? (
          <Link onClick={resetDemo} className={smallProjectBtnClass}>
            <PiRepeat /> start again
          </Link>
        ) : (
          <Link
            to={getUrl(URL.PROJECT_SETTINGS, eventId)}
            onClick={() => trackEditProject('Video Builder')}
            className={smallProjectBtnClass}
          >
            <PiPencil /> edit
          </Link>
        )}
      </div>

      {isMobile || isTablet ? (
        <SellingPoint
          variant="pink"
          text="CrowdClip&reg; is not optimised for mobile devices. For the best experience, use on a desktop."
          icon={<></>}
          addClass="w-full sm:w-1/2 justify-center"
        />
      ) : (
        !isPeopleUploaded &&
        !isHlReelUploaded &&
        !isClipsUploaded && (
          <SellingPoint
            variant="grey-outline"
            text={
              isDemo ? (
                <>
                  Ready to get started? Let's create a sample video project by
                  going through the steps below and with the assets provided.
                </>
              ) : (
                <>
                  First time to use CrowdClip&reg;? Download some sample files
                  to test with.
                  <br />
                  <Link
                    to={ZIP_URL.QUICKSTART}
                    onClick={() => trackQuickstartPackDownload('Video Builder')}
                    className="text-black"
                  >
                    Download the quickstart pack
                  </Link>
                </>
              )
            }
            icon={<></>}
            addClass="w-full justify-center"
          />
        )
      )}

      <div className="w-full flex flex-col gap-4">
        {isAttendeeReview ? arContent : content}
      </div>

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default VideoBuilder;
