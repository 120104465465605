import React from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { getEventImg, getVideoBuilderLink } from '../../../Utils';
import { trackOpenProject } from '../../../analytics';

import aflEventThumbnail from '../../../assets/afl-event-thumbnail.jpeg';

import { EVENT_TYPE_DEMO } from '../../../constants';

import EventBlockOverlay from './eventBlockOverlay';

const EventBlock = (props) => {
  const { event } = props;

  const isDemo = event.type === EVENT_TYPE_DEMO;

  const navigate = useNavigate();

  const onClickEvent = () => {
    trackOpenProject('home', event);
    navigate(getVideoBuilderLink(event));
  };

  const thumbnailClass =
    'w-[var(--event-block-width)] aspect-video rounded-md object-cover';

  return (
    <div className="group w-[var(--event-block-width)]">
      <div className="relative mb-1 sm:mb-3">
        <img
          className={`${thumbnailClass} cursor-pointer`}
          src={
            isDemo ? aflEventThumbnail : getEventImg('thumbnail', event.banner)
          }
          alt="Project Thumbnail"
          onClick={onClickEvent}
        />

        <EventBlockOverlay
          event={event}
          onClickEvent={onClickEvent}
          addClass={thumbnailClass}
        />
      </div>

      <Link
        to={getVideoBuilderLink(event)}
        className="block text-xs sm:text-sm font-bold no-underline text-true-black hover:text-primary-900 hover:opacity-100"
      >
        {event.name}
      </Link>

      {isDemo ? (
        <div className="text-xs italic">A sample event. Check me out.</div>
      ) : (
        <div className="flex justify-between flex-wrap gap-x-2 text-xs opacity-50">
          <div className="capitalize">{event.videoMode}</div>
          <div>{moment(event.modified).fromNow()}</div>
        </div>
      )}
    </div>
  );
};

export default EventBlock;
