import moment from 'moment';
import { object, string, number, array } from 'yup';

let generalSchema = object({
  type: string().required('Project type is required'),
  eventName: string().required('Project name is required'),
});

let brandSchema = object({
  type: string().required('Project type is required'),
  eventName: string().required('Project name is required'),
  startDate: string().test({
    name: 'test-start-date',
    test(value, ctx) {
      let now = moment().utc();

      if (!value) return ctx.createError({ message: 'Start date is required' });
      if (moment(value).isBefore(now, 'day'))
        return ctx.createError({
          message: 'Start date cannot be earlier than current date',
        });
      return true;
    },
  }),
  endDate: string().test({
    name: 'test-end-date',
    test(value, ctx) {
      if (!value) return ctx.createError({ message: 'End date is required' });
      if (moment(value).isBefore(ctx.parent.startDate, 'day'))
        return ctx.createError({
          message: 'End date cannot be earlier than start date',
        });
      return true;
    },
  }),
  orientation: string().required('Orientation is required'),
  wildcards: array(number()).test({
    name: 'test-wildcards',
    test(value, ctx) {
      if (!value.every((v) => parseFloat(v) > 0))
        return ctx.createError({
          message: "Some wildcards' duration is invalid",
        });
      return true;
    },
  }),
});

export const validateForm = async (isBrand, formData) => {
  const errors = {};

  const schema = isBrand ? brandSchema : generalSchema;

  await schema.validate(formData, { abortEarly: false }).catch((err) => {
    err.inner.forEach((error) => {
      errors[error.path] = error.errors[0];
    });
  });

  return errors;
};
