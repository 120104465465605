import { toast } from 'react-toastify';
import { isEmpty } from '../Utils';

import { getCheckInLink } from '../Utils';
import {
  trackCheckInEnterEmailSuccess,
  trackCheckInEnterEmailError,
  trackCheckInUpdatePersonSuccess,
  trackCheckInUpdatePersonError,
  trackCheckInUploadFileSuccess,
  trackCheckInUploadFileError,
  trackCheckInBatchUploadVideoSuccess,
} from '../analytics';

import { EMAILS } from '../constants';

import { sendEmail } from './api/emails';
import {
  attendeeFaceMerge,
  getAttendeeFaceByEmail,
  getAttendeeFaceById,
} from './api/faces';
import { getAttendeeFaceVideos, getAttendeeFacePhotos } from './api/files';
import { updateOneFace } from './api/mongodb';
import { uploadFile } from './uploadFileService';

export const checkIn = async ({
  eventId,
  faceData = {},
  photo = null,
  videos = null,
  faceId = null,
}) => {
  let parentElementId = faceId;

  // create face
  if (!isEmpty(faceData)) {
    await updateOneFace({ eventId, ...faceData })
      .then(() => {
        parentElementId = faceData.elementId;
        trackCheckInUpdatePersonSuccess();
      })
      .catch(() => {
        trackCheckInUpdatePersonError();
      });
  }

  // save and upload face
  if (photo && parentElementId) {
    await uploadFile({
      file: photo.originFileObj || photo,
      eventId,
      parentElementId,
      isPublic: true,
    })
      .then(() => {
        trackCheckInUploadFileSuccess('photo');
      })
      .catch(() => {
        trackCheckInUploadFileError('photo', photo.name);
        toast.error(`${photo.name} file upload failed.`);
      });
  }

  // save and upload UGC
  if (!isEmpty(videos) && parentElementId) {
    await Promise.all(
      videos.map((video) => {
        return new Promise(async (resolve, reject) => {
          return uploadFile({
            file: video.originFileObj || video,
            eventId,
            parentElementId,
            fileKind: 'video-footage',
            isPublic: true,
          })
            .then(() => {
              trackCheckInUploadFileSuccess('video');
              resolve();
            })
            .catch((err) => {
              trackCheckInUploadFileError('video', video.name);
              toast.error(`${video.name} file upload failed.`);
              reject(err);
            });
        });
      }),
    );

    trackCheckInBatchUploadVideoSuccess(videos.length);
  }
};

export const checkInFace = async (event, email) => {
  // skip the check-in process if no event or email
  if (!event || !email) return null;

  try {
    // check if face exists
    let face = await getAttendeeFaceByEmail(event.eventId, email);

    // if face doesn't exist – create new face, send welcome email & track in GA
    if (isEmpty(face)) {
      face = await attendeeFaceMerge(event.eventId, {
        email,
        name: email.split('@')[0],
      });

      const emailData = {
        recipientEmail: email,
        templateId: event.attendeeReviewData?.isAttendeeReview
          ? EMAILS.CHECK_IN_ATTENDEE_REVIEW
          : event.type === 'brand'
            ? EMAILS.CHECK_IN_BRAND
            : EMAILS.CHECK_IN_VIDEOGRAPHER,
        dynamicTemplateData: {
          event_name: event.name,
          share_url: getCheckInLink(event.eventId),
        },
      };

      await sendEmail(emailData);
    }

    // if face exists – just track it in GA
    trackCheckInEnterEmailSuccess(
      `${isEmpty(face) ? 'New' : 'Existing'} attendee`,
    );

    return face;
  } catch (err) {
    trackCheckInEnterEmailError(err);
    throw err;
  }
};

export const getFaceWithFiles = async (eventId, face, refetchFace) => {
  let attendeeFace = { ...face };

  if (refetchFace)
    attendeeFace = await getAttendeeFaceByEmail(eventId, face.email);

  const videos = await getAttendeeFaceVideos(eventId, face.elementId);
  const photos = await getAttendeeFacePhotos(eventId, face.elementId);

  return { ...attendeeFace, videos, photos };
};

export const getFaceWithFilesById = async (eventId, faceId) => {
  let attendeeFace = await getAttendeeFaceById(faceId);

  if (!attendeeFace) return null;

  const videos = await getAttendeeFaceVideos(eventId, faceId);
  const photos = await getAttendeeFacePhotos(eventId, faceId);

  return { ...attendeeFace, videos, photos };
};
