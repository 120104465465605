import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty } from '../../../Utils';

import {
  trackEmailHubClickPreview,
  trackEmailHubChangeTemplateSuccess,
  trackEmailHubChangeTemplateError,
  trackEmailHubClickCustomText,
} from '../../../analytics';

import { Spinner, Button, Badge } from '../../../components';

import { updateOneEvent } from '../../../services/api/mongodb';

import { useEventStore } from '../../../stores/event';
import { useProjectSettingsStore } from '../../../stores/projectSettings';
import { useUserStore } from '../../../stores/user';

import { SGTemplate } from '../../../types';

import {
  EmailHubTemplate,
  getAllEmails,
  getSelectionEmails,
} from './components/Utils';
import CustomTextModal from './components/customTextModal';
import EmailPreviewModal from './components/emailPreviewModal';

const EmailSelection = (props: any) => {
  const { event, emailType } = props;

  const queryParameters = new URLSearchParams(window.location.search);
  const redirectUrl = queryParameters.get('redirectUrl');

  const user = useUserStore((state) => state.user);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);
  const customEmailTemplates = useProjectSettingsStore(
    (state) => state.customEmailTemplates,
  );

  const [previewTemplate, setPreviewTemplate] = useState<SGTemplate>({});
  const [customText, setCustomText] = useState<string>('');
  const [showCustomTextModal, setShowCustomTextModal] =
    useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const navigate = useNavigate();

  const eventId = event.eventId;
  const emailTemplates = getSelectionEmails(emailType);
  const emailsData = event?.emailsData ?? {};

  useEffect(() => {
    let text = '';
    if (emailType === 'creation') text = emailsData.creationTemplateText ?? '';
    setCustomText(text);
  }, [emailType, emailsData.creationTemplateText]);

  const handleSelect = async (templateId: any) => {
    try {
      setIsSaving(true);

      const data: any = { eventId };
      if (emailType === 'creation') data.creationTemplateId = templateId;

      await updateOneEvent(data);
      await updateCurrentEvent();

      toast.success('Email preferences are updated');
      trackEmailHubChangeTemplateSuccess(emailType);

      if (redirectUrl) navigate(redirectUrl);
    } catch {
      toast.error('Something went wrong. Try again later');
      trackEmailHubChangeTemplateError(emailType);
    } finally {
      setIsSaving(false);
    }
  };

  const pageTitle =
    getAllEmails(event).find((e) => e.selectionUrl === window.location.pathname)
      ?.title ?? 'Email';

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h3 className="font-semibold leading-[2.5rem]">
            {pageTitle} Selection
          </h3>

          <div>Choose one of the templates below</div>
        </div>

        <div className="flex flex-wrap gap-8">
          {emailTemplates.map((email: EmailHubTemplate) => {
            const template: SGTemplate = customEmailTemplates[emailType]
              ? customEmailTemplates[emailType][email.templateId]
              : null;

            if (!template) return null;

            return (
              <div className="w-full sm:w-[calc((100%_-_(2_*_2rem))_/_3)] text-center cursor-pointer">
                <div className="group relative w-full aspect-[3/2]">
                  <div className="w-full h-full absolute top-0 left-0 flex flex-col items-center justify-center gap-4 bg-black-overlay-800 text-white rounded-md transition-all duration-300 opacity-0 hover:opacity-100">
                    {email.isComingSoon ? (
                      <div>
                        <div className="text-lg font-bold">Coming Soon</div>
                        <div className="text-sm">Stay tuned</div>
                      </div>
                    ) : isSaving ? (
                      <Spinner size="xl" color="white" />
                    ) : (
                      <React.Fragment>
                        <Button
                          onClick={() => {
                            setPreviewTemplate(template);
                            trackEmailHubClickPreview('Selection');
                          }}
                          variant="white-outline"
                          isThin
                          className="w-32"
                        >
                          Preview
                        </Button>

                        <Button
                          onClick={() => handleSelect(email.templateId)}
                          variant="white-outline"
                          isThin
                          className="w-32"
                          disabled={email.isCustom && !customText}
                          allowPointerEvents
                          popoverBody="Add Text first"
                          hidePopover={!email.isCustom || !!customText}
                          isThinPopover
                        >
                          Select
                        </Button>

                        {email.isCustom && (
                          <Button
                            onClick={() => {
                              setShowCustomTextModal(true);
                              trackEmailHubClickCustomText(emailType);
                            }}
                            variant="white-outline"
                            isThin
                            className="w-32"
                          >
                            {customText ? 'Edit' : 'Add'} Text
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>

                  <img
                    src={template.thumbnail_url}
                    alt="Template Thumbnail"
                    className="w-full h-full object-cover object-top rounded-md border-1 border-grey-200 border-solid"
                  />
                </div>

                <div className="flex items-center justify-center mt-2 gap-1">
                  <div className="font-bold">{email.title}</div>
                  {emailsData.creationTemplateId === email.templateId && (
                    <Badge variant="black" size="sm" text="Current" />
                  )}

                  {email.isComingSoon && (
                    <Badge variant="grey" size="sm" text="Coming Soon" />
                  )}
                </div>

                <div className="text-sm text-black/[0.5]">
                  {email.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <EmailPreviewModal
        show={!isEmpty(previewTemplate)}
        onHide={() => setPreviewTemplate({})}
        template={previewTemplate}
        event={event}
        user={user}
        customText={customText}
      />

      <CustomTextModal
        show={showCustomTextModal}
        onHide={() => setShowCustomTextModal(false)}
        event={event}
        emailType={emailType}
      />
    </div>
  );
};

export default EmailSelection;
