import { create } from 'zustand';

import { isProduction } from '../Utils';

import {
  fetchTemplates,
  getAllEmails,
  getSelectionEmails,
} from '../pages/ProjectSettings/EmailHubTab/components/Utils';

import { getCreationWithData } from '../services/api/creations';

export const SETTINGS_PREVIEW_EVENT_ID = isProduction()
  ? 'eb6e8339-32f7-4787-924d-c74040644410'
  : '42569265-cbdd-4f23-bd7c-9fccc14ac542';

export const SETTINGS_PREVIEW_FACE_ID = isProduction()
  ? 'd2da39b4-1f3f-40a1-bf05-497e98b0802b'
  : '62b810c1-5c08-46dd-9173-f5afe157d73b';

export const useProjectSettingsStore = create((set, get) => {
  return {
    previewPerson: {},
    allEmailTemplates: [],
    customEmailTemplates: {},

    setPreviewPerson: async () => {
      const previewPerson = await getCreationWithData(
        SETTINGS_PREVIEW_EVENT_ID,
        SETTINGS_PREVIEW_FACE_ID,
      );

      set((state) => ({ ...state, previewPerson }));
    },

    setAllEmailTemplates: async (event) => {
      const emails = getAllEmails(event);
      const allEmailTemplates = await fetchTemplates(emails);

      set((state) => ({ ...state, allEmailTemplates }));
    },

    setCustomEmailTemplates: async (emailType) => {
      const emails = getSelectionEmails(emailType);
      const emailTemplates = await fetchTemplates(emails);

      set((state) => ({
        ...state,
        customEmailTemplates: {
          ...get().customEmailTemplates,
          [emailType]: emailTemplates,
        },
      }));
    },
  };
});
