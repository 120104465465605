import { getHashtag } from '../../Utils';
import config from '../../config';

import { useEventStore } from '../../stores/event';

import { getAccessTokenClient, getApiKeyClient } from '../client/http';
import { findOneEvent } from './mongodb';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const apiKeyClient = () => getApiKeyClient(baseUrl);

export const mergeEvent = async (formData) => {
  try {
    const response = await accessTokenClient().put('api/event', formData);
    return response.data.event;
  } catch (err) {
    throw new Error('Error creating event', err);
  }
};

export const setEventMode = async (data, isPublic = false) => {
  try {
    await (isPublic ? apiKeyClient : accessTokenClient)().put(
      'api/event/videoMode',
      data,
    );
    return { success: true };
  } catch (err) {
    throw new Error('Error setting event video mode', err);
  }
};

export const getUserEvents = async (userId) => {
  try {
    const response = await accessTokenClient().get(
      `api/events?userId=${userId}&sort=latest`,
    );

    const events = response.data.events;

    useEventStore.setState((state) => ({ ...state, userEvents: events }));
    return events;
  } catch (err) {
    throw new Error('Error getting user events', err);
  }
};

export const getEvent = async (eventId) => {
  try {
    const response = await accessTokenClient().get(`api/events/${eventId}`);
    return response.data.event;
  } catch (err) {
    if (err.response?.status === 404) return null;
    else throw new Error('Error getting event', err);
  }
};

export const getEventPublic = async (eventId) => {
  if (!eventId) return null;

  try {
    const response = await apiKeyClient().get(`api/events/${eventId}`);
    const event = response.data.event;
    const dbEvent = await findOneEvent(eventId);

    const returnEvent = {
      eventId: event.eventId,
      name: event.name,
      type: event.type,
      stage: event.stage,
      startDate: event.startDate,
      endDate: event.endDate,
      videoMode: event.videoMode,
      wildcards: event.wildcards,
      banner: event.banner,
      logo: event.logo,
      caption: event.caption || '',
      hashtag: getHashtag(event.hashTag),
      TsAndCsLink: dbEvent.TsAndCsLink,
      organiserName: dbEvent.organiserName,
      customFieldLabel: event.customFieldLabel,
      created: event.created,
      payment: { paid: dbEvent.payment?.paid ?? false },
      attendeeReviewData: dbEvent.attendeeReviewData ?? null,
      assigneeData: dbEvent.assigneeData ?? null,
    };

    return returnEvent;
  } catch (err) {
    if (err.response?.status === 404) {
      return null;
    } else throw new Error('Error getting event', err);
  }
};

// getEventName is used in public Share Hub and Share Video pages
export const getEventName = async (eventId) => {
  try {
    const response = await apiKeyClient().get(`api/events/${eventId}`);
    return response.data.event.name;
  } catch (err) {
    throw new Error('Error getting event', err);
  }
};

export const getEventTypes = async () => {
  try {
    const response = await accessTokenClient().get('api/event-types');
    return response.data;
  } catch (err) {
    throw new Error('Error getting event types', err);
  }
};
