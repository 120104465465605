import React from 'react';
import {
  CheckCircle,
  PlusCircle,
  ArrowCircleRight,
  UsersThree,
  Slideshow,
  CardsThree,
  ListMagnifyingGlass,
  ShareNetwork,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const VBBtn = (props) => {
  const { type, heading, subheading, isComplete, link } = props;

  const navigate = useNavigate();

  const isUploadBtn = ['people', 'hl-reel', 'clips'].includes(type);

  const getMainIcon = () => {
    const iconClass = 'w-8 h-8 sm:w-10 sm:h-10';

    switch (type) {
      case 'people':
        return <UsersThree className={iconClass} />;
      case 'hl-reel':
        return <Slideshow className={iconClass} />;
      case 'clips':
        return <CardsThree className={iconClass} />;
      case 'review':
        return <ListMagnifyingGlass className={iconClass} />;
      case 'share':
        return <ShareNetwork className={iconClass} />;
      default:
        return null;
    }
  };

  const getSideIcon = () => {
    const iconClass = classNames({
      'w-8 h-8 sm:w-10 sm:h-10': true,
      'text-purple-700': !isUploadBtn && !isComplete,
      'text-grey-900': isUploadBtn && !isComplete,
      'text-success-900': isComplete,
    });

    if (isComplete) return <CheckCircle className={iconClass} />;

    if (isUploadBtn) return <PlusCircle className={iconClass} />;

    return <ArrowCircleRight className={iconClass} />;
  };

  const btnClass = classNames({
    'w-full flex items-center p-3 gap-3 text-left no-underline rounded-md border-1 transition-all hover:text-white': true,
    'border-purple-700 text-purple-700 hover:bg-gradient-purple-light':
      !isUploadBtn && !isComplete,
    'border-grey-900 text-gray-500 hover:bg-grey-900':
      isUploadBtn && !isComplete,
    'border-success-900 text-success-900 hover:bg-success-900': isComplete,
    'pointer-events-none': !link,
  });

  return (
    <button
      id={`vb-btn-${type}`}
      className={btnClass}
      onClick={() => navigate(link)}
    >
      {getMainIcon()}

      <div className="w-[calc(100%_-_1.5rem_-_4rem)] sm:w-[calc(100%_-_1.5rem_-_5rem)]">
        {heading && (
          <div className="text-lg font-bold uppercase">{heading}</div>
        )}

        {subheading && <div className="text-sm">{parse(subheading)}</div>}
      </div>

      <div className="ml-auto rounded-full bg-white">{getSideIcon()}</div>
    </button>
  );
};

export default VBBtn;
