import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

import {
  trackUploadShotReminderAccept,
  trackUploadShotReminderReject,
} from '../../../analytics';

import landscapeShot from '../../../assets/shot-placeholder-landscape.jpg';
import portraitShot from '../../../assets/shot-placeholder-portrait.jpg';

import { Spinner, Button } from '../../../components';

import { useEventStore } from '../../../stores/event';

const ShotModal = (props) => {
  const { show, onHide, mode, handleUpload, rejectUpload } = props;

  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isSaved, setIsSaved] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateEvent = async () => {
    if (mode) return;

    setIsUpdating(true);
    await updateCurrentEvent();
    setIsUpdating(false);
  };

  // accept upload & hide modal
  const handleAccept = async () => {
    await handleUpdateEvent();

    handleUpload();
    onHide();

    // save in localStorage that reminder is saved (won't show again)
    if (isSaved) localStorage.setItem('hlReelShotSaved', 'true');

    trackUploadShotReminderAccept(isSaved);
  };

  // reject upload & hide modal
  const handleReject = async () => {
    await handleUpdateEvent();

    rejectUpload();
    onHide();

    trackUploadShotReminderReject();
  };

  return (
    <Modal show={show} onHide={handleReject} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Reminder</Modal.Title>
      </Modal.Header>

      <Modal.Body className="flex flex-col gap-4">
        <div>
          Please ensure you have added the "SHOT" wildcards slots to the
          highlight reel before uploading.
        </div>

        <img
          className="max-w-full max-h-80 self-center rounded-md border-1 border-black border-solid"
          src={mode === 'portrait' ? portraitShot : landscapeShot}
          alt="Shot Placeholder"
        />

        <Form.Check id="check-api-checkbox">
          <Form.Check.Input
            value={isSaved}
            onChange={(e) => setIsSaved(e.target.checked)}
          />
          <Form.Check.Label>Don't show again</Form.Check.Label>
        </Form.Check>

        {isUpdating ? (
          <div className="w-full h-[var(--form-el-height)] flex items-center justify-center">
            <Spinner size="lg" />
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row gap-4">
            <Button
              variant="pink"
              className="w-full sm:w-1/2"
              onClick={handleReject}
            >
              Cancel Upload
            </Button>
            <Button className="w-full sm:w-1/2" onClick={handleAccept}>
              I Have "SHOT" Included
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ShotModal;
