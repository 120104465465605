import React from 'react';
import classNames from 'classnames';
import { PiCaretLeft, PiCaretRight, PiX } from 'react-icons/pi';

import { Button } from '.';

const HeadingBtnGroup = (props) => {
  const { group, id, setId } = props;

  const onClick = (type) => {
    const index = group.findIndex((faceObj) => faceObj.face.elementId === id);

    if (type === 'next') {
      let nextIndex = index + 1;
      if (nextIndex >= group.length) nextIndex = 0;
      setId(group[nextIndex].face.elementId);
    } else if (type === 'prev') {
      let prevIndex = index - 1;
      if (prevIndex < 0) prevIndex = group.length - 1;
      setId(group[prevIndex].face.elementId);
    } else if (type === 'close') {
      setId(null);
    }
  };

  return (
    <div className="flex">
      {group.length > 1 && (
        <React.Fragment>
          <Button
            id="heading-left-btn"
            variant="light-grey"
            className="w-12 text-[1.2rem] rounded-r-none"
            isThin
            onClick={() => onClick('prev')}
          >
            <PiCaretLeft />
          </Button>

          <Button
            id="heading-right-btn"
            variant="light-grey"
            className="w-12 text-[1.2rem] rounded-none border-x border-grey-200 border-solid"
            isThin
            onClick={() => onClick('next')}
          >
            <PiCaretRight />
          </Button>
        </React.Fragment>
      )}

      <Button
        id="heading-close-btn"
        variant="light-grey"
        className={classNames('w-12 text-[1.2rem]', {
          'rounded-l-none': group.length > 1,
        })}
        isThin
        onClick={() => onClick('close')}
      >
        <PiX />
      </Button>
    </div>
  );
};

export default HeadingBtnGroup;
