import React, { useEffect, useState } from 'react';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { Modal, Form, FloatingLabel } from 'react-bootstrap';
import { PiCheckCircle, PiInfo } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty } from '../../../../Utils';

import {
  trackCreateProject,
  trackDemoUpsellClickNextEventForm,
  trackDemoUpsellSubmitNextEventForm,
  trackDemoUpsellClickAskCC,
} from '../../../../analytics';

import FormFeedback from '../../../../components/form/formFeedback';
import PopoverTrigger from '../../../../components/popoverTrigger';
import SubmitBtn from '../../../../components/submitBtn';

import { URL, FORMSPARK_ID } from '../../../../constants';

import { validateForm } from './nextEventFormValidations';

const DemoUpsellModal = (props) => {
  const { show, onHide } = props;

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    numOfPeople: '',
    msg: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (show)
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 1060,
      });
  }, [show]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({});
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const errors = await validateForm(formData);

    if (!isEmpty(errors)) {
      setErrors(errors);
      toast.error('Some data is missing or invalid');

      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post(
        `https://submit-form.com/${FORMSPARK_ID.NEXT_EVENT}`,
        formData,
      );

      toast.success("Thanks, we'll get back to you soon.");
      setShowForm(false);
      trackDemoUpsellSubmitNextEventForm();
    } catch (error) {
      toast.error('There was a problem submitting the form. Please try again.');
    }

    setIsSubmitting(false);
  };

  const btnClass =
    'w-4/5 h-[var(--form-el-height)] flex items-center justify-center px-3 py-1.5 bg-primary-900 text-md sm:text-lg rounded-md no-underline hover:opacity-80 disabled:opacity-65';

  return (
    <React.Fragment>
      {/* main modal */}
      <Modal show={show && !showForm} onHide={onHide} centered>
        <Modal.Body className="flex flex-col items-center p-8 gap-4 text-center">
          <PiCheckCircle className="w-24 h-24 text-[var(--success-color)]" />

          <h4>You did it!</h4>

          <div className="text-lg">
            You’ve successfully generated a personalised video which is now on
            its way to your inbox.{' '}
            <PopoverTrigger
              content={
                <span>
                  <PiInfo className="cursor-pointer hover:fill-[url('#icon-primary-gradient')]" />
                </span>
              }
              placement="bottom"
              popoverBody={
                <div>
                  This is an <b>example video</b> and it doesn't reflect the
                  choices you've made before.
                </div>
              }
            />
          </div>

          <div className="text-lg">Where to from here?</div>

          <div className="w-full flex flex-col items-center justify-center gap-4">
            <Link
              className={`${btnClass} text-white`}
              to={URL.PROJECT_SETTINGS}
              onClick={() => trackCreateProject('Demo upsell modal')}
            >
              Create Project
            </Link>

            <button
              className={`${btnClass} text-white`}
              onClick={() => {
                setShowForm(true);
                trackDemoUpsellClickNextEventForm();
              }}
            >
              Tell Us About Your Next Event
            </button>

            <button
              className={`${btnClass} bg-transparent text-black border-1 border-primary-900 border-solid gap-2 cursor-default hover:opacity-100`}
              onClick={trackDemoUpsellClickAskCC}
            >
              Ask CrowdClip&reg;!
              <span className="text-xs">Coming Soon</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* next event modal */}
      <Modal
        show={showForm}
        onHide={() => setShowForm(false)}
        size="lg"
        centered
      >
        <Modal.Body className="flex flex-col items-center p-8 gap-4 text-center">
          <h4>
            Tell us a little bit more about your upcoming event. One of our
            superstar team members will get in touch with you.
          </h4>

          <Form className="w-full text-left" onSubmit={onSubmit} noValidate>
            <div className="flex flex-col sm:flex-row gap-4">
              <FloatingLabel label="Name*" className="w-full sm:w-1/2">
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={onChange}
                  placeholder="Name"
                  isInvalid={errors.name}
                  required
                />
                <FormFeedback error={errors.name} />
              </FloatingLabel>

              <FloatingLabel label="Email*" className="w-full sm:w-1/2">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={onChange}
                  placeholder="Email"
                  isInvalid={errors.email}
                  required
                />
                <FormFeedback error={errors.email} />
              </FloatingLabel>
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <FloatingLabel label="Event Date*" className="w-full sm:w-1/2">
                <Form.Control
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={onChange}
                  placeholder="dd/mm/yyyy"
                  isInvalid={errors.date}
                  required
                />
                <FormFeedback error={errors.date} />
              </FloatingLabel>

              <FloatingLabel
                label="Number of people*"
                className="w-full sm:w-1/2"
              >
                <Form.Control
                  type="text"
                  name="numOfPeople"
                  value={formData.numOfPeople}
                  onChange={onChange}
                  placeholder="Number of people"
                  isInvalid={errors.numOfPeople}
                  required
                />
                <FormFeedback error={errors.numOfPeople} />
              </FloatingLabel>
            </div>

            <FloatingLabel label="Message*">
              <Form.Control
                as="textarea"
                type="text"
                name="msg"
                value={formData.msg}
                onChange={onChange}
                placeholder="Message"
                isInvalid={errors.msg}
                required
              />
              <FormFeedback error={errors.msg} />
            </FloatingLabel>

            <SubmitBtn
              title="Submit"
              isProcessing={isSubmitting}
              className="w-full"
            />
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DemoUpsellModal;
