import React, { useState, useEffect } from 'react';

import { goTo, getUrl } from '../../Utils';

import { trackPaymentSuccess, trackPaymentError } from '../../analytics';

import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';

import { URL, STRIPE_PRODUCTS } from '../../constants';

import { updateOneEvent, updateOneFace } from '../../services/api/mongodb';
import { retrieveSession } from '../../services/api/stripe';

import Error from './error';

const PaymentRedirect = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get('type');
  const sessionId = queryParameters.get('sessionId');

  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const gaLabel =
        type.charAt(0).toUpperCase() + type.slice(1).replace(/-/g, ' ');

      try {
        const session = await retrieveSession(sessionId);

        const referenceId = session.client_reference_id;
        const eventId = referenceId.split('_')[0] ?? null;
        const elementId = referenceId.split('_')[1] ?? null;

        const paymentStripeId = session.payment_intent;
        const paymentType = session.submit_type;
        const paymentAmount = session.amount_total / 100;

        // project remove watermark
        if (type === STRIPE_PRODUCTS.REMOVE_WATERMARK_PROJECT) {
          await updateOneEvent({ eventId, paymentStripeId, paymentAmount });

          trackPaymentSuccess(gaLabel);
          goTo(`${getUrl(URL.VB_SHARE, eventId)}?payment_success=true`);
        }

        // attendee remove watermark or attendee video donation
        else if (
          type === STRIPE_PRODUCTS.REMOVE_WATERMARK_ATTENDEE ||
          type === STRIPE_PRODUCTS.ATTENDEE_VIDEO_DONATION
        ) {
          await updateOneFace({
            eventId,
            elementId,
            paymentStripeId,
            paymentType,
            paymentAmount,
          });

          const url =
            getUrl(URL.SHARE, eventId, elementId) +
            `?paymentState=${type === STRIPE_PRODUCTS.REMOVE_WATERMARK_ATTENDEE ? 'watermark' : 'donation'}-confirmation`;

          trackPaymentSuccess(gaLabel);
          goTo(url);
        }

        // if type is unknown – show error
        else setPageError(404);
      } catch (error) {
        setError("We're having trouble verifying your payment with Stripe");
        trackPaymentError(gaLabel);
      }
    };

    getSession();
  }, [sessionId, type]);

  if (!!pageError) return <Error code={pageError} />;

  return (
    <React.Fragment>
      <PageSpinner isLoading noTips isFullPage />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
        isContactUs
      />
    </React.Fragment>
  );
};

export default PaymentRedirect;
