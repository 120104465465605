import { create } from 'zustand';
import { getUserEvents, getEventPublic } from '../services/api/events';
import { findOneEvent } from '../services/api/mongodb';
import { useUserStore } from './user';

export const useEventStore = create((set, get) => {
  return {
    eventId: null,
    userEvents: [],
    currentEvent: null,
    zipOption: null,
    clipSelections: [],
    generationStatus: null,

    setZipOption: (zipOption) => {
      set((state) => ({ ...state, zipOption }));
    },

    setClipSelections: (clipSelections) => {
      set((state) => ({
        ...state,
        clipSelections,
      }));
    },

    setGenerationStatus: (generationStatus) => {
      set((state) => ({
        ...state,
        generationStatus,
      }));
    },

    getClipSelections: (eventId) => {
      return get().clipSelections[eventId];
    },

    setCurrentEvent: (event) => {
      set((state) => ({ ...state, currentEvent: event }));
    },

    setUserEvents: (events) => {
      set((state) => ({ ...state, userEvents: events }));
    },

    setEventId: async (eventId, isAllowAssignees) => {
      if (!eventId) {
        set((state) => ({ ...state, eventId: null, currentEvent: null }));
        return;
      }

      let currentEvent = null;
      const event =
        get().userEvents.find((event) => event.eventId === eventId) ?? null;
      const dbEvent = await findOneEvent(eventId);
      const assigneeData =
        JSON.parse(localStorage.getItem('assigneeData')) ?? {};

      // if event doesn't exist
      if (!dbEvent) currentEvent = { error: 404 };
      // if user is owner of the event
      else if (!!event) currentEvent = { ...dbEvent, ...event };
      // if it's assignee accessing the page
      else if (isAllowAssignees && assigneeData.hasAccess)
        currentEvent = { ...dbEvent, eventId, isAssigneeAccess: true };
      // else – user has no access to the page
      else currentEvent = { error: 403 };

      set((state) => ({ ...state, eventId, currentEvent }));
    },

    setDemoEvent: (eventId) => {
      const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

      set((state) => ({ ...state, eventId, currentEvent: demoData.event }));
    },

    setAssigneeEvent: async (eventId) => {
      const event = await getEventPublic(eventId);

      set((state) => ({
        ...state,
        eventId,
        currentEvent: { ...event, isAssigneeAccess: true } ?? { error: 404 },
      }));
    },

    changeEvent: (eventId) => {
      // get user events list
      const userEvents = get().userEvents;

      // set current event
      const event = userEvents.find((event) => event.eventId === eventId);
      set((state) => ({
        ...state,
        currentEvent: event,
        zipOption: null,
        clipSelections: [],
        generationStatus: null,
      }));

      // clear localStorage with previous event data (grouped)
      // general
      localStorage.removeItem('videoModeSaved');
    },

    updateCurrentEvent: async () => {
      const user = useUserStore.getState().user;
      const currentEvent = get().currentEvent;

      // update user events list
      const events = user?.session?.userId
        ? await getUserEvents(user.session.userId)
        : [];
      const dbEvent = await findOneEvent(currentEvent.eventId);

      // update current event
      const event =
        events.find((event) => event.eventId === currentEvent.eventId) ?? null;

      set((state) => ({
        ...state,
        currentEvent: !dbEvent
          ? { error: 404 }
          : { ...currentEvent, ...dbEvent, ...event },
      }));
    },
  };
});
