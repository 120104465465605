import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  panelIndex: number;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, panelIndex, index, ...other } = props;

  return (
    <div
      id={`project-sidenav-tabpanel-${index}`}
      aria-labelledby={`project-sidenav-tab-${index}`}
      role="tabpanel"
      className="max-w-[calc(100vw_-_var(--page-spacing)_-_3.5rem)] h-96 pl-[calc(var(--page-spacing)_+_0.5rem)] pr-2 sm:p-4"
      hidden={panelIndex !== index}
      {...other}
    >
      {panelIndex === index && (
        <div className="w-80 max-w-full h-full p-4 bg-white shadow-grey-2 rounded-md">
          {children}
        </div>
      )}
    </div>
  );
};

export default TabPanel;
