import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

import { Badge } from './';

const ContentHeader = (props) => {
  const { title, subtitle, className, withDemoBadge } = props;

  return (
    <div className={classNames('flex flex-col gap-1', className)}>
      {title && (
        <div className="flex items-center gap-2 flex-wrap">
          <div className="text-[1.75rem] sm:text-[2.5rem] font-medium leading-tight">
            {parse(title)}
          </div>

          {withDemoBadge && (
            <Badge
              text="Sample"
              variant="purple-gradient"
              popoverBody="This is a Sample Project, where you can try out CrowdClip® without providing any assets."
            />
          )}
        </div>
      )}

      {subtitle && (
        <div className="text-md sm:text-lg text-black/[0.5]">
          {parse(subtitle)}
        </div>
      )}
    </div>
  );
};

export default ContentHeader;
