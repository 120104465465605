import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { isEmpty, getFileImg, getBase64 } from '../../../Utils';

import DraggerSimplified from '../../../components/draggerSimplified';
import ConfirmModal from '../../../components/modals/confirmModal';
import ErrorModal from '../../../components/modals/errorModal';
import SubmitBtn from '../../../components/submitBtn';
import UploadInfoPoints from '../../../components/uploadInfoPoints';

import { URL } from '../../../constants';

import { checkIn } from '../../../services/checkInService';

import { getDetailsText } from './checkinText';
import DetailsSection from './detailsSection';

const DetailsStage = (props) => {
  const { event, face, isPreview } = props;

  const [name, setName] = useState(face.name ?? '');
  const [instagram, setInstagram] = useState(face.instagram ?? '');
  const [customFieldData, setCustomFieldData] = useState(
    face.customFieldData ?? '',
  );
  const [selfieFile, setSelfieFile] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState(
    face.photos[0] ? getFileImg(face.photos[0]) : '',
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isPreview) return;

    setIsProcessing(true);
    setShowConfirmModal(true);

    try {
      const faceData = {
        elementId: face.elementId,
        name,
        instagram,
        customFieldData,
      };

      await checkIn({
        eventId: event.eventId,
        faceData,
        photo: selfieFile,
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onHideModal = () => {
    setShowConfirmModal(false);
    navigate(`${URL.CHECK_IN}${event.eventId}`);
    window.location.reload();
  };

  const changeSelfie = (file) => {
    getBase64(file, (url) => setSelfieUrl(url));
    setSelfieFile(file);
  };

  const { selfieInfoPoints, tipsList } = getDetailsText();

  return (
    <React.Fragment>
      <Form className="flex flex-col gap-4" onSubmit={onSubmit} noValidate>
        <h5>
          <b>Step 1</b>: Fill in your details
        </h5>

        <DetailsSection
          event={event}
          name={name}
          setName={setName}
          instagram={instagram}
          setInstagram={setInstagram}
          customFieldData={customFieldData}
          setCustomFieldData={setCustomFieldData}
        />

        <hr />

        <h5>
          <b>Step 2</b>: Upload a photo or a selfie*
        </h5>

        <DraggerSimplified
          id="ci-photo-upload"
          type="image"
          heading="Photo upload (1 max)"
          imgUrl={selfieUrl}
          onDrop={changeSelfie}
          isPreview={isPreview}
        />

        <UploadInfoPoints points={selfieInfoPoints} />

        <SubmitBtn
          title="Submit"
          isProcessing={isProcessing}
          isDisabled={isEmpty(name) || isEmpty(selfieUrl)}
        />
      </Form>

      <ConfirmModal
        show={showConfirmModal}
        onHide={onHideModal}
        isProcessing={isProcessing}
        heading={
          isProcessing
            ? "We're updating your details"
            : `Great! You have successfully checked in to ${event.name}, ${name}.`
        }
        tipsList={tipsList}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </React.Fragment>
  );
};

export default DetailsStage;
