import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Form, FloatingLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getCheckInLink, getEventImg, getBase64 } from '../../../Utils';

import { trackEditProjectChangeImg } from '../../../analytics';

import eventCustomField from '../../../assets/event-custom-field.png';
import eventImages from '../../../assets/event-images.png';
import eventTsAndCs from '../../../assets/event-ts-and-cs.png';

import DraggerSimplified from '../../../components/draggerSimplified';
import { getTsAndCsText } from '../../CheckIn/components/TsAndCsText';
import CheckInPageUI from '../../CheckIn/components/checkInPageUI';
import TabComponent from '../components/tabComponent';

const CheckInTab = (props) => {
  const { event } = props;

  const [bannerUrl, setBannerUrl] = useState(
    getEventImg('banner', event.banner),
  );
  const [bannerFile, setBannerFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(getEventImg('logo', event.logo));
  const [logoFile, setLogoFile] = useState(null);
  const [TsAndCsLink, setTsAndCsLink] = useState(event.TsAndCsLink ?? '');
  const [organiserName, setOrganiserName] = useState(event.organiserName ?? '');
  const [customFieldLabel, setCustomFieldLabel] = useState(
    event.customFieldLabel ?? '',
  );

  const previewRef = useRef(null);
  const [previewScreen, setPreviewScreen] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(0);

  useEffect(() => {
    if (previewRef.current) {
      const rect = previewRef.current.getBoundingClientRect();
      setPreviewWidth(rect.width);
    }
  }, []);

  const onChangeImg = (file, isBanner) => {
    getBase64(file, (url) => {
      if (isBanner) setBannerUrl(url);
      else setLogoUrl(url);
    });

    if (isBanner) setBannerFile(file);
    else setLogoFile(file);

    trackEditProjectChangeImg(isBanner ? 'banner' : 'logo');
  };

  const imgBlockClass = classNames('w-full flex flex-col gap-2', {
    'sm:w-1/2': previewScreen === -1,
  });

  return (
    <TabComponent
      title="Check-In Page"
      subtitle={
        <>
          Customise the project{' '}
          <Link to={getCheckInLink(event.eventId)} target="_blank">
            Check-In page
          </Link>
          , where your attendees can self check-in.
        </>
      }
      event={event}
      data={{
        bannerFile,
        logoFile,
        TsAndCsLink,
        organiserName,
        customFieldLabel,
      }}
      blocks={[
        {
          title: 'Banner & Logo',
          description:
            'The banner and logo will be shown at the top of the Check-In page.',
          infoHeader: 'Setting up your Banner & Logo',
          infoBody: (
            <React.Fragment>
              <div>
                The banner and logo will be shown at the top of the Check-In
                page.
              </div>

              <img src={eventImages} alt="Banner & Logo Graphic" />
            </React.Fragment>
          ),
          content: (
            <div
              className={classNames('flex flex-col', {
                'sm:flex-row gap-6 sm:gap-10': previewScreen === -1,
                'gap-4': previewScreen !== -1,
              })}
            >
              <div className={imgBlockClass}>
                <DraggerSimplified
                  type="image"
                  imgUrl={bannerUrl}
                  isDashboard
                  onDrop={(file) => onChangeImg(file, true)}
                />

                <div className="text-sm italic">
                  Pro Tip: for the best experience, make sure your banner fits a
                  16:9 aspect ratio.
                </div>
              </div>

              <div className={imgBlockClass}>
                <DraggerSimplified
                  type="image"
                  imgUrl={logoUrl}
                  isDashboard
                  onDrop={(file) => onChangeImg(file, false)}
                />

                <div className="text-sm italic">
                  Pro Tip: for the best experience, make sure your logo fits a
                  1:1 aspect ratio.
                </div>
              </div>
            </div>
          ),
        },
        {
          title: 'Terms & Conditions',
          description: (
            <>
              Add your T&C to appear on check-in page along with CrowdClip&reg;
              T&C
            </>
          ),
          infoHeader: 'Setting up your Terms & Conditions',
          infoBody: (
            <React.Fragment>
              <div>
                The Terms & Conditions (if existing) will be shown on the
                Check-In page. Attendees cannot proceed without accepting them.
              </div>

              <img src={eventTsAndCs} alt="T&C Graphic" />

              <div>
                The Organiser name (if provided) will be shown instead of the
                "event organiser's" text. The T&C hyperlink will lead the
                attendee to the provided Link.
              </div>
            </React.Fragment>
          ),
          content: (
            <React.Fragment>
              <FloatingLabel label="Link">
                <Form.Control
                  aria-label="Link"
                  type="text"
                  name="TsAndCsLink"
                  value={TsAndCsLink}
                  onChange={(e) => setTsAndCsLink(e.target.value)}
                  placeholder="Link"
                />
              </FloatingLabel>

              <FloatingLabel label="Organiser name">
                <Form.Control
                  aria-label="Organiser name"
                  type="text"
                  name="organiserName"
                  value={organiserName}
                  onChange={(e) => setOrganiserName(e.target.value)}
                  placeholder="Organiser name"
                />
              </FloatingLabel>

              <div className="text-sm">
                <b>What attendees see</b>:{' '}
                {getTsAndCsText(TsAndCsLink, organiserName)}
              </div>
            </React.Fragment>
          ),
        },
        {
          title: 'Custom Field',
          description: 'Add a custom property to appear on check-in page',
          infoHeader: 'Setting up your Custom Field',
          infoBody: (
            <React.Fragment>
              <div>
                The Custom Field (if existing) will be shown as an additional
                optional field for attendees to fill on the Check-In page.
              </div>

              <img src={eventCustomField} alt="Custom Field Graphic" />
            </React.Fragment>
          ),
          content: (
            <React.Fragment>
              <FloatingLabel label="Custom field label">
                <Form.Control
                  aria-label="Custom field label"
                  type="text"
                  name="customFieldLabel"
                  value={customFieldLabel}
                  onChange={(e) => setCustomFieldLabel(e.target.value)}
                  placeholder="Custom field label"
                />

                <Form.Text muted>E.g. Seat number</Form.Text>
              </FloatingLabel>
            </React.Fragment>
          ),
        },
      ]}
      preview={{
        previewRef: previewRef,
        show: previewScreen !== -1,
        onShow: () => setPreviewScreen(0),
        onHide: () => setPreviewScreen(-1),
        onClickLeft: previewScreen === 1 && (() => setPreviewScreen(0)),
        onClickRight: previewScreen === 0 && (() => setPreviewScreen(1)),
        content: (
          <CheckInPageUI
            isPreview
            width={previewWidth}
            event={{ ...event, TsAndCsLink, organiserName, customFieldLabel }}
            bannerUrl={bannerUrl}
            logoUrl={logoUrl}
            face={previewScreen === 1 && { photos: [] }}
          />
        ),
        note: 'You can fill in the details, but not submit the form',
      }}
    />
  );
};

export default CheckInTab;
