import React from 'react';
import { Upload } from 'antd';
import classNames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
import { PiUserCircleFill, PiUserRectangleFill } from 'react-icons/pi';

import { isEmpty } from '../Utils';

const DraggerSimplified = (props) => {
  const {
    id,
    type,
    heading,
    isMultiple,
    isDashboard,
    imgUrl,
    onDrop,
    isPreview,
  } = props;

  const beforeUpload = (file, fileList) => {
    onDrop(file, fileList);
    return false;
  };

  let draggerProps = { id, name: 'file', showUploadList: false };

  if (type === 'video') draggerProps = { ...draggerProps, accept: '.mov,.mp4' };
  else if (type === 'image')
    draggerProps = { ...draggerProps, accept: '.bmp,.gif,.jpeg,.jpg,.png' };

  if (isMultiple) draggerProps = { ...draggerProps, multiple: true };
  else draggerProps = { ...draggerProps, maxCount: 1, multiple: false };

  const iconClass =
    "fill-[url('#icon-primary-gradient')] group-hover:animate-pulsing";
  const icon =
    type === 'video' ? (
      <PiUserRectangleFill className={iconClass} size={100} />
    ) : (
      <PiUserCircleFill className={iconClass} size={100} />
    );

  const imageClass = classNames({
    'object-cover': true,
    'rounded-full w-24 h-24 sm:w-32 sm:h-32': !isDashboard,
    'rounded-md max-w-[90%] sm:max-w-[50%] max-h-40': isDashboard,
  });

  return (
    <Upload.Dragger
      {...draggerProps}
      className="group shadow-xl"
      beforeUpload={(file, fileList) => beforeUpload(file, fileList)}
    >
      <div className="min-h-40 h-auto sm:h-40 flex flex-col sm:flex-row items-center justify-center gap-4 px-4 text-base text-center sm:text-left">
        {imgUrl ? (
          <img className={imageClass} src={imgUrl} alt="Uploaded Graphic" />
        ) : (
          icon
        )}

        <div>
          {heading && <div className="font-bold">{heading}</div>}

          <div className={heading ? '' : 'font-bold'}>
            {isMobile || isTablet || isPreview
              ? 'Click to upload'
              : 'Drag and drop or click to upload'}
          </div>

          {!isEmpty(imgUrl) && (
            <div className="text-sm italic">
              Note: This will replace the current image
            </div>
          )}
        </div>
      </div>
    </Upload.Dragger>
  );
};

export default DraggerSimplified;
