import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getUrl } from '../../../../Utils';
import { sendBulkFaceEmail } from '../../../../UtilsEmail';

import {
  trackShareBulkShare,
  trackShareDemoShare,
} from '../../../../analytics';

import { Button } from '../../../../components';
import { URL, EMAILS } from '../../../../constants';

import { sendEmail } from '../../../../services/api/emails';

import { useUserStore } from '../../../../stores/user';

import DemoUpsellModal from './demoUpsellModal';

const MainShareBtn = (props) => {
  const {
    event,
    people,
    isDemo,
    peopleEmails,
    setPeopleEmails,
    creationTemplate,
    afterEmailSend,
  } = props;

  const user = useUserStore((state) => state.user);

  const [isEmailsSending, setIsEmailsSending] = useState(false);
  const [showDemoUpsellModal, setShowDemoUpsellModal] = useState(false);

  const navigate = useNavigate();

  const handleMainShare = async (e) => {
    const peopleUpd = people.map((person) => person.face);

    const { isSuccess } = await sendBulkFaceEmail(
      e,
      'individual-creation',
      event,
      peopleUpd,
      peopleEmails,
      setPeopleEmails,
      setIsEmailsSending,
    );

    if (isSuccess) {
      afterEmailSend();
      trackShareBulkShare();
    }
  };

  const handleDemoShare = async () => {
    setIsEmailsSending(true);

    const person = people[0];

    const emailData = {
      recipientEmail: user.email,
      templateId: EMAILS.CREATION_SHARE,
      dynamicTemplateData: {
        event_name: event.name,
        name: person.face.name,
        share_url: peopleEmails[person.face.elementId].videoLink,
      },
    };

    const isSuccess = await sendEmail(emailData);

    if (isSuccess) {
      setShowDemoUpsellModal(true);
      trackShareDemoShare();
    } else
      toast.error(
        <div>
          Email couldn't be sent to <b>{user.email}</b>, please try again later.
        </div>,
      );

    setIsEmailsSending(false);
  };

  const shareBtnData = useMemo(() => {
    const eventId = event.eventId;

    // demo shares with user only
    if (isDemo)
      return {
        title: 'Share',
        onClick: () => handleDemoShare(),
        disabled: false,
        popoverHeader: 'Share to yourself',
        popoverBody:
          'Share a personalised highlight reel with yourself to preview the email your attendees will receive',
      };

    // no email template is selected
    if (isEmpty(creationTemplate))
      return {
        title: 'Select Template',
        onClick: () =>
          navigate(
            `${getUrl(URL.PROJECT_SETTINGS, eventId, 'email-hub/creation')}?redirectUrl=${getUrl(URL.VB_SHARE, eventId)}`,
          ),
        disabled: false,
        popoverHeader: 'Select email template',
        popoverBody:
          'Before sharing, select the email template the attendees will receive',
      };

    // no attendee emails are provided
    if (
      Object.keys(peopleEmails)
        .map((id) => peopleEmails[id])
        .every((person) => !person.email)
    )
      return {
        title: 'Share to All',
        disabled: true,
        popoverHeader: 'Share to all',
        popoverBody:
          'Unable to share, as no attendee emails are provided. Fill in the emails below, and share either individually or in bulk',
      };

    // can share to all
    return {
      title: 'Share to All',
      onClick: (e) => handleMainShare(e),
      disabled: false,
      popoverHeader: 'Share to all',
      popoverBody: 'Share personalised highlight reels to all with email below',
    };
  }, [creationTemplate, event.eventId, isDemo, navigate, peopleEmails]);

  return (
    <React.Fragment>
      <Button
        onClick={shareBtnData.onClick}
        variant="black"
        form="pill"
        className="w-full sm:w-52 text-lg"
        isSubmitBtn
        isProcessing={isEmailsSending}
        disabled={shareBtnData.disabled}
        allowPointerEvents
        popoverHeader={shareBtnData.popoverHeader}
        popoverBody={shareBtnData.popoverBody}
      >
        {shareBtnData.title}
      </Button>

      {/* demo complete modal */}
      <DemoUpsellModal show={showDemoUpsellModal} />
    </React.Fragment>
  );
};

export default MainShareBtn;
