import React, { useState, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getUrl, canEditEvent, isProduction } from '../../Utils';

import { trackCreateProjectError } from '../../analytics';

import { Button } from '../../components';
import BackBtn from '../../components/backBtn';
import ContentHeader from '../../components/contentHeader';
import ErrorModal from '../../components/modals/errorModal';
import PageContainer from '../../components/pageContainer';
import PageSpinner from '../../components/pageSpinner';

import { URL, EVENT_TYPE_VIDEOGRAPHER } from '../../constants';

import { mergeEvent, getUserEvents } from '../../services/api/events';
import { setAttendeeReviewData } from '../../services/api/mongodb';

import { useEventStore } from '../../stores/event';
import { useProjectSettingsStore } from '../../stores/projectSettings';
import { useUserStore } from '../../stores/user';

import CheckInTab from './CheckInTab/checkInTab';
import EmailHubTab from './EmailHubTab/emailHub';
import EmailSelection from './EmailHubTab/emailSelection';
import GeneralTab from './GeneralTab/generalTab';
import ShareVideoTab from './ShareVideoTab/shareVideoTab';

export const SETTINGS_PREVIEW_EVENT_ID = isProduction()
  ? 'eb6e8339-32f7-4787-924d-c74040644410'
  : '42569265-cbdd-4f23-bd7c-9fccc14ac542';

export const SETTINGS_PREVIEW_FACE_ID = isProduction()
  ? 'd2da39b4-1f3f-40a1-bf05-497e98b0802b'
  : '62b810c1-5c08-46dd-9173-f5afe157d73b';

const ProjectSettings = () => {
  const { eventId, tab, subtab } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const isAttendeeReview = queryParameters.get('isAttendeeReview') === 'true';

  const user = useUserStore((state) => state.user);
  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const {
    previewPerson,
    setPreviewPerson,
    allEmailTemplates,
    setAllEmailTemplates,
    customEmailTemplates,
    setCustomEmailTemplates,
  } = useProjectSettingsStore((state) => ({
    previewPerson: state.previewPerson,
    setPreviewPerson: state.setPreviewPerson,
    allEmailTemplates: state.allEmailTemplates,
    setAllEmailTemplates: state.setAllEmailTemplates,
    customEmailTemplates: state.customEmailTemplates,
    setCustomEmailTemplates: state.setCustomEmailTemplates,
  }));

  const currentEventRef = useRef(currentEvent);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);

  const navigate = useNavigate();

  const isMobileDevice = useMemo(
    () => window.innerWidth < 1024,
    [window.innerWidth],
  );

  // set tab if not given
  useEffect(() => {
    if (eventId && !tab && !isMobileDevice)
      navigate(getUrl(URL.PROJECT_SETTINGS, eventId, 'general'));
  }, [eventId, navigate, tab, isMobileDevice]);

  // set event id on mount
  useEffect(() => {
    if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (!eventId) await createEvent();
      else if (currentEvent) {
        if (currentEvent.error) setPageError(currentEvent.error);

        const isEventChanged = currentEventRef.current !== currentEvent;

        switch (tab) {
          case 'share-video':
            if (isEmpty(previewPerson)) await setPreviewPerson();
            break;

          case 'email-hub':
            if (isEmpty(allEmailTemplates) || isEventChanged)
              await setAllEmailTemplates(currentEvent);

            if (
              subtab &&
              (isEmpty(customEmailTemplates[subtab]) || isEventChanged)
            )
              await setCustomEmailTemplates(subtab);
            break;

          default:
            break;
        }

        currentEventRef.current = currentEvent;

        setIsLoading(false);
      }
    };

    onMount();
  }, [currentEvent, subtab, tab]);

  const createEvent = async () => {
    const formData = new FormData();

    formData.append('userId', user.session.userId);
    formData.append('name', 'Untitled');
    formData.append('type', EVENT_TYPE_VIDEOGRAPHER);

    try {
      const event = await mergeEvent(formData);
      const eventId = event.eventId;

      await getUserEvents(user.session.userId);

      if (isAttendeeReview)
        await setAttendeeReviewData({ eventId, isAttendeeReview: 'true' });

      navigate(getUrl(URL.VIDEO_BUILDER, eventId));
    } catch {
      trackCreateProjectError();
      toast.error('Something went wrong. Try again later');
      navigate(URL.HOME);
    }
  };

  const pathname = window.location.pathname;

  const pageTabs = [
    { label: 'General', value: 'general' },
    { label: 'Check-In Page', value: 'check-in' },
    { label: 'Share Video Page', value: 'share-video' },
    { label: 'Email Hub', value: 'email-hub' },
  ];

  const renderContent = () => {
    if (isEmpty(currentEvent)) return null;

    switch (tab) {
      case 'check-in':
        return <CheckInTab event={currentEvent} />;

      case 'share-video':
        return <ShareVideoTab event={currentEvent} />;

      case 'email-hub':
        if (subtab)
          return <EmailSelection event={currentEvent} emailType={subtab} />;
        return <EmailHubTab event={currentEvent} />;

      case 'general':
      default:
        return <GeneralTab event={currentEvent} />;
    }
  };

  const backBtnData = useMemo(() => {
    if (tab === 'email-hub' && !!subtab)
      return {
        label: 'Email Hub',
        url: getUrl(URL.PROJECT_SETTINGS, eventId, 'email-hub'),
      };

    return {
      label: 'Project Settings',
      url: getUrl(URL.PROJECT_SETTINGS, eventId),
      className: classNames({ 'flex lg:hidden': !!tab, hidden: !tab }),
    };
  }, [eventId, subtab, tab]);

  return (
    <PageContainer
      title={`Project Settings – ${currentEvent?.name}`}
      addClass="h-full flex gap-6 sm:gap-10"
      isFullPage={isMobileDevice}
    >
      <ContentHeader title="Project Settings" className="px-[5vw] lg:px-0" />

      <div className="flex flex-col lg:flex-row gap-8">
        {/* desktop menu */}
        <div className="hidden lg:block w-1/6">
          <div className="w-full sticky top-[calc(var(--header-height)_+_var(--content-vertical-spacing))]">
            {pageTabs.map((pageTab) => (
              <Button
                key={pageTab.value}
                onClick={() =>
                  navigate(getUrl(URL.PROJECT_SETTINGS, eventId, pageTab.value))
                }
                variant="transparent"
                isThin
                className={classNames(
                  'w-full justify-start text-sm text-left',
                  {
                    'font-semibold': pathname.includes(pageTab.value),
                    'text-black/[0.5]': !pathname.includes(pageTab.value),
                  },
                )}
              >
                {pageTab.label}
              </Button>
            ))}
          </div>
        </div>

        {/* mobile menu */}
        <div
          className={classNames({ 'w-full lg:hidden': !tab, hidden: !!tab })}
        >
          {pageTabs.map((pageTab) => (
            <Button
              key={pageTab.value}
              onClick={() =>
                navigate(getUrl(URL.PROJECT_SETTINGS, eventId, pageTab.value))
              }
              variant="custom"
              className="w-full justify-start px-[5vw] text-sm text-left first:border-t border-b border-grey-200 border-solid"
            >
              {pageTab.label}
            </Button>
          ))}
        </div>

        <div className="w-full lg:w-5/6 flex flex-col gap-6 sm:gap-10 lg:gap-0">
          {/* back btn */}
          <BackBtn
            onClick={() => navigate(backBtnData?.url)}
            label={backBtnData?.label}
            className={backBtnData?.className}
          />

          {/* content */}
          <PageSpinner isLoading={isLoading} pageError={pageError}>
            <div
              className={classNames('px-[5vw] lg:p-0', {
                'hidden lg:block': !tab,
              })}
            >
              {renderContent()}
            </div>
          </PageSpinner>
        </div>
      </div>

      <ErrorModal
        show={!isLoading && !canEditEvent(currentEvent)}
        heading="Sorry, this project cannot be edited"
        subheading="You may not have access to edit this project or it has already started."
      />
    </PageContainer>
  );
};

export default ProjectSettings;
