import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { PiArrowSquareOut, PiEnvelope } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import {
  trackEmailHubClickPreview,
  trackEmailHubClickTestEmail,
  trackEmailHubClickChange,
} from '../../../../analytics';

import { Button, Badge } from '../../../../components';

import { useProjectSettingsStore } from '../../../../stores/projectSettings';

import { SGTemplate } from '../../../../types';
import { EmailHubTemplate } from './Utils';

interface Props {
  email: EmailHubTemplate;
  setPreviewTemplate: Dispatch<SetStateAction<SGTemplate>>;
  setSendTestTemplate: Dispatch<SetStateAction<SGTemplate>>;
}

const EmailBlock = (props: Props) => {
  const { email, setPreviewTemplate, setSendTestTemplate } = props;

  const allEmailTemplates = useProjectSettingsStore(
    (state) => state.allEmailTemplates,
  );

  const template = allEmailTemplates[email.templateId];

  const navigate = useNavigate();

  const onClickPreview = (source: string) => {
    const { templateId, selectionUrl } = email;

    // if template is selected – open its preview
    if (templateId) {
      setPreviewTemplate(template);
      trackEmailHubClickPreview(source);
    }

    // else – go to selection page
    else if (selectionUrl) {
      navigate(selectionUrl);
      trackEmailHubClickChange(source);
    }
  };

  const onClickTestEmail = () => {
    setSendTestTemplate(template);
    trackEmailHubClickTestEmail();
  };

  const onClickChange = () => {
    navigate(email.selectionUrl ?? '');
    trackEmailHubClickChange('Button');
  };

  const thumbnailClass =
    'w-full sm:w-52 aspect-[3/2] flex items-center justify-center text-lg font-medium text-uppercase rounded-md cursor-pointer transition-all duration-300';

  if (!email.show) return null;

  return (
    <React.Fragment>
      <div className="w-full flex flex-col sm:flex-row items-center gap-4 sm:gap-16">
        {/* thumbnail section */}
        <div
          className={classNames(thumbnailClass, 'group relative')}
          onClick={() => onClickPreview('Thumbnail')}
        >
          {template ? (
            <React.Fragment>
              <div
                className={classNames(
                  thumbnailClass,
                  'absolute top-0 left-0 bg-black-overlay-800 text-white opacity-0 hover:opacity-100',
                )}
              >
                Preview
              </div>

              <img
                src={template.thumbnail_url}
                alt="Template Thumbnail"
                className={classNames(
                  thumbnailClass,
                  'object-cover object-top border-1 border-grey-200 border-solid',
                )}
              />
            </React.Fragment>
          ) : (
            <div
              className={classNames(
                thumbnailClass,
                'group border-1 border-grey-200 border-solid text-grey-900 hover:text-primary-900 hover:border-primary-900 hover:bg-primary-50',
              )}
            >
              Select
            </div>
          )}
        </div>

        {/* details and actions section */}
        <div className="w-full sm:w-[calc(100%_-_13rem_-_4rem)] flex flex-col lg:flex-row gap-4 lg:gap-16">
          <div className="w-full lg:w-[calc(100%_-_(2_*_4rem)_-_6rem_-_(2_*_2.5rem)_-_0.5rem)]">
            <div className="flex mb-1 gap-1 items-center">
              <div className="font-bold">{email.title}</div>

              {email.isAutomatic && (
                <Badge
                  size="sm"
                  text="Automatic"
                  popoverBody="No action required on your end"
                />
              )}

              {email.isOptional && (
                <Badge
                  variant="grey"
                  size="sm"
                  text="Optional"
                  popoverBody="It’s not essential, and might be used in few cases"
                />
              )}
            </div>

            <div className="text-sm text-black/[0.5]">{email.description}</div>
          </div>

          {/* buttons Section */}
          <div className="w-full sm:w-auto flex flex-row-reverse lg:flex-row justify-end lg:justify-start gap-2 lg:gap-16">
            {email.selectionUrl ? (
              <Button onClick={onClickChange} isThin className="w-24">
                Change
              </Button>
            ) : (
              <div className="w-24" />
            )}

            <div className="flex gap-2">
              <Button
                onClick={() => onClickPreview('Button')}
                variant="square-icon"
                disabled={!template}
                popoverBody="Preview email"
                isThinPopover
              >
                <PiArrowSquareOut />
              </Button>

              <Button
                onClick={onClickTestEmail}
                variant="square-icon"
                disabled={!template}
                popoverBody="Send a test email"
                isThinPopover
              >
                <PiEnvelope />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <hr className="m-0 last:hidden" />
    </React.Fragment>
  );
};

export default EmailBlock;
