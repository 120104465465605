import React, { useState } from 'react';
import classNames from 'classnames';
import VideoThumbnail from 'react-video-thumbnail';

import { getFileUrl, getThumbnail } from '../../../Utils';

const VideoBlock = (props) => {
  const { video, onClick } = props;

  const [thumbnailImg, setThumbnailImg] = useState(null);

  const thumbnailClass = classNames({
    'relative w-[calc((100%_-_1rem)_/_2)] sm:w-[calc((100%_-_(1rem_*_2))_/_3)] aspect-video rounded-md': true,
    'cursor-pointer transition-all hover:scale-110': !!onClick,
  });

  return (
    <div
      className={thumbnailClass}
      key={video.elementId ?? video.uid}
      onClick={() => onClick && onClick(video)}
    >
      <img
        src={thumbnailImg ?? getThumbnail(video.frameId)}
        className="w-full h-full object-cover rounded-md"
        alt="Video Thumbnail"
      />

      {!video.frameId && (
        <VideoThumbnail
          videoUrl={getFileUrl(video.elementId, video.name)}
          thumbnailHandler={(thumbnail) => setThumbnailImg(thumbnail)}
          renderThumbnail={false}
        />
      )}
    </div>
  );
};

export default VideoBlock;
