import moment from 'moment';
import ReactGA from 'react-ga4';

import { getDemoSuffix } from '../Utils';

export const trackCreateProject = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'click_create_project',
    label: source,
  });
};

export const trackCreateProjectError = () => {
  ReactGA.event({
    category: 'User',
    action: 'create_project_error',
  });
};

export const trackOpenProject = (source, event) => {
  ReactGA.event({
    category: 'User',
    action: `open_project_from_${source}`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
  });
};

export const trackProjectComplete = (event) => {
  const timestampMills = moment(new Date()).valueOf();

  ReactGA.event({
    category: 'User',
    action: `project_complete${getDemoSuffix(event)}`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
    value: timestampMills,
  });
};

export const trackFirstProjectComplete = (event) => {
  const timestampMills = moment(new Date()).valueOf();

  ReactGA.event({
    category: 'User',
    action: `first_project_complete${getDemoSuffix(event)}`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
    value: timestampMills,
  });
};

// PROJECT SETTINGS

export const trackEditProject = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'click_edit_project',
    label: source,
  });
};

export const trackEditProjectChangeImg = (type) => {
  ReactGA.event({
    category: 'User',
    action: `edit_project_change_${type}`,
  });
};

export const trackUpdateProjectSuccess = () => {
  ReactGA.event({
    category: 'User',
    action: 'update_project_success',
  });
};

export const trackUpdateProjectError = () => {
  ReactGA.event({
    category: 'User',
    action: 'update_project_error',
  });
};

// email hub

export const trackEmailHubClickPreview = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_click_preview',
    label: source,
  });
};

export const trackEmailHubClickTestEmail = () => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_click_test_email',
  });
};

export const trackEmailHubSendTestEmailSuccess = (count) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_send_test_email_success',
    label: count,
  });
};

export const trackEmailHubSendTestEmailError = (count) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_send_test_email_error',
    label: count,
  });
};

export const trackEmailHubClickChange = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_click_change',
    label: source,
  });
};

export const trackEmailHubChangeTemplateSuccess = (label) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_change_template_success',
    label,
  });
};

export const trackEmailHubChangeTemplateError = (label) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_change_template_error',
    label,
  });
};

export const trackEmailHubClickCustomText = (label) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_click_custom_text',
    label,
  });
};

export const trackEmailHubUpdateCustomTextSuccess = (label) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_update_custom_text_success',
    label,
  });
};

export const trackEmailHubUpdateCustomTextError = (label) => {
  ReactGA.event({
    category: 'User',
    action: 'email_hub_update_custom_text_error',
    label,
  });
};

// ASSETS

export const trackCheckInQRDownload = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'check_in_qr_download',
    label: source,
  });
};
