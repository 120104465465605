import React, { useState, useEffect, useRef } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

import { getHashtag } from '../../../Utils';
import eventCaption from '../../../assets/event-caption.png';

import { useProjectSettingsStore } from '../../../stores/projectSettings';

import ShareVideoUI from '../../Share/shareVideoUI';
import TabComponent from '../components/tabComponent';

const getHashtagText = (hashtagArray) => {
  return '#' + hashtagArray.join(' #');
};

const ShareVideoTab = (props) => {
  const { event } = props;

  const previewPerson = useProjectSettingsStore((state) => state.previewPerson);

  const [caption, setCaption] = useState(event.caption ?? '');
  const [hashtag, setHashtag] = useState(getHashtagText(event.hashTag ?? []));
  const [hashtagArray, setHashtagArray] = useState(event.hashTag ?? []);

  const previewRef = useRef(null);
  const [previewScreen, setPreviewScreen] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(0);

  useEffect(() => {
    if (previewRef.current) {
      const rect = previewRef.current.getBoundingClientRect();
      setPreviewWidth(rect.width);
    }
  }, []);

  const onChangeHashtag = (e) => {
    const hashtagArray = e.target.value
      .split(/[\s#,.]+/)
      .filter((tag, index) => tag !== '' || index !== 0);

    setHashtag(getHashtagText(hashtagArray));
    setHashtagArray(hashtagArray);
  };

  return (
    <TabComponent
      title="Share Page"
      subtitle={
        <>
          Customise the project Share Video page, where your attendees can watch
          and share their personalised videos.
        </>
      }
      event={event}
      data={{ caption, hashtagArray }}
      blocks={[
        {
          title: 'Caption and Hashtags',
          description:
            'Provide your attendees with a caption and hashtags they can use in their social media posts',
          infoHeader: 'Setting up your Caption & Hashtags',
          infoBody: (
            <React.Fragment>
              <div>
                Together with video, your attendees will receive hashtags and
                caption to copy and paste to social media.
              </div>

              <img src={eventCaption} alt="Caption Graphic" />
            </React.Fragment>
          ),
          content: (
            <React.Fragment>
              <FloatingLabel label="Caption">
                <Form.Control
                  aria-label="Caption"
                  as="textarea"
                  type="text"
                  name="caption"
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  placeholder="Caption"
                />
              </FloatingLabel>

              <FloatingLabel label="Hashtag">
                <Form.Control
                  aria-label="Hashtag"
                  type="text"
                  name="hashtag"
                  value={hashtag === '#' ? '' : hashtag}
                  onChange={onChangeHashtag}
                  placeholder="Hashtag"
                />
                <Form.Text muted>
                  #crowdclip hashtag will be added to the list automatically
                </Form.Text>
              </FloatingLabel>
            </React.Fragment>
          ),
        },
      ]}
      preview={{
        previewRef: previewRef,
        show: previewScreen !== -1,
        onShow: () => setPreviewScreen(0),
        onHide: () => setPreviewScreen(-1),
        content: (
          <ShareVideoUI
            isPreview
            width={previewWidth}
            event={{ ...event, caption, hashtag: getHashtag(hashtagArray) }}
            person={previewPerson}
          />
        ),
        note: 'Some of the actions are disabled in preview',
      }}
    />
  );
};

export default ShareVideoTab;
