import React from 'react';
import moment from 'moment';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { PiList, PiBellFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { Spinner, Button } from '..';

import { trackCreateProject } from '../../analytics';

import { URL } from '../../constants';

import { useUserStore } from '../../stores/user';

import Logo from '../logo';
import ProfileDropdown from './profileDropdown';

const Header = (props) => {
  const { hasProjSideNav, toggleSideNav } = props;

  const user = useUserStore((state) => state.user);
  const accessToken = useUserStore((state) => state.accessToken);

  const pathname = window.location.pathname;

  // on event check-in page – header only has logo
  const showLogoOnly = React.useMemo(() => {
    return [URL.REVIEW_VIDEO].reduce(
      (acc, url) => acc || pathname.includes(url),
      false,
    );
  }, [pathname]);

  const navigate = useNavigate();

  const onLoginClick = () => {
    if (pathname.includes(URL.MANAGED_SERVICES)) {
      // store the current page URL in local storage (to redirect back after login)
      const currentPage = window.location.href;
      localStorage.setItem('previousPage', currentPage);
    }

    navigate(URL.LOGIN);
  };

  // hard-coded list of notifications
  const notifications = [
    {
      text: 'Template upload complete: ACTAI template.mp4',
      created: new Date('2023-10-30T13:53:00'),
    },
    {
      text: 'Footage upload complete: video-00-15.mp4',
      created: new Date('2023-10-30T13:57:00'),
    },
    {
      text: 'Footage upload complete: video-00-23.mp4',
      created: new Date('2023-10-30T14:01:00'),
    },
    {
      text: 'Footage upload complete: video-02-01.mp4',
      created: new Date('2023-10-30T14:06:00'),
    },
    {
      text: 'Footage upload complete: video-02-22.mp4',
      created: new Date('2023-10-30T14:11:00'),
    },
    {
      text: 'Footage upload complete: video-02-32.mp4',
      created: new Date('2023-10-30T14:16:00'),
    },
    {
      text: 'Footage upload complete: video-03-57.mp4',
      created: new Date('2023-10-30T14:22:00'),
    },
    {
      text: 'Footage upload complete: video-05-57.mp4',
      created: new Date('2023-10-30T14:32:00'),
    },
    {
      text: 'Footage upload complete: video-06-03.mp4',
      created: new Date('2023-10-30T14:45:00'),
    },
    {
      text: 'Footage upload complete: video-07-42.mp4',
      created: new Date('2023-10-30T14:57:00'),
    },
    {
      text: 'Generating videos',
      isProcessing: true,
    },
  ];

  const handleCreateEvent = () => {
    navigate(URL.PROJECT_SETTINGS);
    trackCreateProject('Header');
  };

  // sort in chronological order from newest to oldest
  let sortedNotifications = notifications.sort((one, other) => {
    if (other.created > one.created) return 1;
    return -1;
  });

  const notificationFeature = false;

  const headerClass =
    'fixed top-0 left-0 w-screen h-[var(--header-height)] flex items-center justify-between px-[var(--page-spacing)] py-1 bg-gradient-primary-right text-white z-[100]';

  if (showLogoOnly)
    return (
      <div className={headerClass}>
        <Logo isWhite noLink />
      </div>
    );

  if (accessToken && user)
    return (
      <header className={headerClass}>
        <div className="flex items-center gap-4">
          {hasProjSideNav && (
            <Button variant="transparent-white" isThin onClick={toggleSideNav}>
              <PiList size="2rem" />
            </Button>
          )}

          <Logo isWhite />
        </div>

        <div className="flex items-center gap-8">
          {notificationFeature && (
            <DropdownButton
              title={<PiBellFill size="1.5rem" />}
              variant="transparent-white"
              className="notification"
              align="end"
            >
              <div className="flex items-center justify-between gap-4 font-bold">
                <div>Enable Notifications</div>

                <Form.Check id="custom-switch" type="switch">
                  <Form.Check.Input />
                </Form.Check>
              </div>

              <Dropdown.Divider />

              <div className="max-h-52 flex flex-col gap-2 text-sm overflow-y-scroll overflow-x-hidden">
                {sortedNotifications.map((notification) => (
                  <div className="flex items-center justify-between gap-4">
                    <div>{notification.text}</div>

                    {notification.isProcessing ? (
                      <Spinner size="xs" />
                    ) : (
                      <div className="opacity-50">
                        {moment(notification.created).fromNow()}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </DropdownButton>
          )}

          {/* not shown on small screens */}
          <Button
            variant="white"
            className="hidden sm:flex font-medium"
            isThin
            onClick={handleCreateEvent}
          >
            Create Project
          </Button>

          <ProfileDropdown />
        </div>
      </header>
    );

  return (
    <div className={headerClass}>
      <Logo isWhite />

      <Button variant="white" className="w-24" isThin onClick={onLoginClick}>
        Log In
      </Button>
    </div>
  );
};

export default Header;
