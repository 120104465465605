const config = {
  apiKey: process.env.REACT_APP_CC_API_KEY,
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  apiBaseUrl: {
    auth: process.env.REACT_APP_AUTH_API_BASE_URL,
    elements: process.env.REACT_APP_ELEMENTS_API_BASE_URL,
    media: process.env.REACT_APP_MEDIA_API_BASE_URL,
    sessions: process.env.REACT_APP_SESSIONS_API_BASE_URL,
    upload: process.env.REACT_APP_UPLOAD_API_BASE_URL,
    queues: process.env.REACT_APP_QUEUES_API_BASE_URL,
    baseApi: process.env.REACT_APP_API_BASE_URL,
    bffApi: process.env.REACT_APP_BFF_BASE_URL,
  },
  baseS3BucketPrefix: process.env.REACT_APP_BASE_S3_BUCKET_PREFIX,

  featureToggles: {
    auth0: process.env.REACT_APP_FEATURE_TOGGLE_AUTH0 === 'true', //convert string to boolean
  },
  stripeLinks: {
    singleEvent: process.env.REACT_APP_STRIPE_SINGLE_EVENT,
    removeWatermarkProject:
      process.env.REACT_APP_STRIPE_REMOVE_WATERMARK_PROJECT,
    removeWatermarkAttendee:
      process.env.REACT_APP_STRIPE_REMOVE_WATERMARK_ATTENDEE,
    attendeeVideoDonation: process.env.REACT_APP_STRIPE_ATTENDEE_VIDEO_DONATION,
  },
  googleAnalyticsTagID: process.env.REACT_APP_GOOGLE_TAG_ID,
  appEnv: process.env.REACT_APP_ENV,
  pixelID: process.env.REACT_APP_PIXEL_ID,
  hotjarID: process.env.REACT_APP_HOTJAR_ID,
};

export default config;
